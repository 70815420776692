/*!
 * Coolhole Slate Theme
 * Floppyright <current_year> - ur mum
 */
/*!/THEMES/SLATE: Bootstrap*/
/*!
 * Bootstrap v4.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}
article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", "Segoe UI", "sans-serif";
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #dcdedd;
  text-align: left;
  background-color: #273034;
}
[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}
hr {
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}
abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}
address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}
ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}
dt {
  font-weight: 700;
}
dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1rem;
}
b,
strong {
  font-weight: bolder;
}
small {
  font-size: 80%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a {
  color: #78cbe4;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #96d6ea;
  text-decoration: underline;
}
a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus {
  outline: 0;
}
pre,
code,
kbd,
samp {
  font-family: "Roboto Mono", Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}
pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}
figure {
  margin: 0 0 1rem;
}
img {
  vertical-align: middle;
  border-style: none;
}
svg {
  overflow: hidden;
  vertical-align: middle;
}
table {
  border-collapse: collapse;
}
caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #c7c8c7;
  text-align: left;
  caption-side: bottom;
}
th {
  text-align: inherit;
}
label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
button {
  border-radius: 0;
}
button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
select {
  word-wrap: normal;
}
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
input[type="radio"],
input[type="checkbox"] {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0;
}
input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}
textarea {
  overflow: auto;
  resize: vertical;
}
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
output {
  display: inline-block;
}
summary {
  display: list-item;
  cursor: pointer;
}
template {
  display: none;
}
[hidden] {
  display: none !important;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
}
h1,
.h1 {
  font-size: 35px;
}
h2,
.h2 {
  font-size: 28px;
}
h3,
.h3 {
  font-size: 24.5px;
}
h4,
.h4 {
  font-size: 21px;
}
h5,
.h5 {
  font-size: 17.5px;
}
h6,
.h6 {
  font-size: 14px;
}
.lead {
  font-size: 17.5px;
  font-weight: 300;
}
.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}
.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}
.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}
.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid #3d464b;
}
small,
.small {
  font-size: 80%;
  font-weight: 400;
}
mark,
.mark {
  padding: 0.2em;
  background-color: #fff07c;
}
.list-unstyled {
  padding-left: 0;
  list-style: none;
}
.list-inline {
  padding-left: 0;
  list-style: none;
}
.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}
.initialism {
  font-size: 90%;
  text-transform: uppercase;
}
.blockquote {
  margin-bottom: 1rem;
  font-size: 17.5px;
}
.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #c7c8c7;
}
.blockquote-footer::before {
  content: "\2014\00A0";
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
.img-thumbnail {
  padding: 0.25rem;
  background-color: #181e21;
  border: 1px solid #3d464b;
  border-radius: 0.25rem;
  -webkit-box-shadow: 4px 5px 2px #13171a;
          box-shadow: 4px 5px 2px #13171a;
  max-width: 100%;
  height: auto;
}
.figure {
  display: inline-block;
}
.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}
.figure-caption {
  font-size: 90%;
  color: #1d2428;
}
code {
  font-size: 87.5%;
  color: #fc5184;
  word-break: break-word;
}
a > code {
  color: inherit;
}
kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #dcdedd;
  background-color: #13171a;
  border-radius: 0.2rem;
  -webkit-box-shadow: 2px 3px 1px #13171a;
          box-shadow: 2px 3px 1px #13171a;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
  -webkit-box-shadow: none;
          box-shadow: none;
}
pre {
  display: block;
  font-size: 87.5%;
  color: #dcdedd;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}
.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}
.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}
.grid-column,
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col {
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  max-width: 100%;
}
.col-auto {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}
.col-1 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 8.33333333%;
          flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}
.col-2 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 16.66666667%;
          flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}
.col-3 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
          flex: 0 0 25%;
  max-width: 25%;
}
.col-4 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 33.33333333%;
          flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}
.col-5 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 41.66666667%;
          flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}
.col-6 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%;
}
.col-7 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 58.33333333%;
          flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}
.col-8 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 66.66666667%;
          flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}
.col-9 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 75%;
          flex: 0 0 75%;
  max-width: 75%;
}
.col-10 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 83.33333333%;
          flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}
.col-11 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 91.66666667%;
          flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}
.col-12 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  max-width: 100%;
}
.order-first {
  -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
          order: -1;
}
.order-last {
  -webkit-box-ordinal-group: 14;
      -ms-flex-order: 13;
          order: 13;
}
.order-0 {
  -webkit-box-ordinal-group: 1;
      -ms-flex-order: 0;
          order: 0;
}
.order-1 {
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
}
.order-2 {
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;
}
.order-3 {
  -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
          order: 3;
}
.order-4 {
  -webkit-box-ordinal-group: 5;
      -ms-flex-order: 4;
          order: 4;
}
.order-5 {
  -webkit-box-ordinal-group: 6;
      -ms-flex-order: 5;
          order: 5;
}
.order-6 {
  -webkit-box-ordinal-group: 7;
      -ms-flex-order: 6;
          order: 6;
}
.order-7 {
  -webkit-box-ordinal-group: 8;
      -ms-flex-order: 7;
          order: 7;
}
.order-8 {
  -webkit-box-ordinal-group: 9;
      -ms-flex-order: 8;
          order: 8;
}
.order-9 {
  -webkit-box-ordinal-group: 10;
      -ms-flex-order: 9;
          order: 9;
}
.order-10 {
  -webkit-box-ordinal-group: 11;
      -ms-flex-order: 10;
          order: 10;
}
.order-11 {
  -webkit-box-ordinal-group: 12;
      -ms-flex-order: 11;
          order: 11;
}
.order-12 {
  -webkit-box-ordinal-group: 13;
      -ms-flex-order: 12;
          order: 12;
}
.offset-1 {
  margin-left: 8.33333333%;
}
.offset-2 {
  margin-left: 16.66666667%;
}
.offset-3 {
  margin-left: 25%;
}
.offset-4 {
  margin-left: 33.33333333%;
}
.offset-5 {
  margin-left: 41.66666667%;
}
.offset-6 {
  margin-left: 50%;
}
.offset-7 {
  margin-left: 58.33333333%;
}
.offset-8 {
  margin-left: 66.66666667%;
}
.offset-9 {
  margin-left: 75%;
}
.offset-10 {
  margin-left: 83.33333333%;
}
.offset-11 {
  margin-left: 91.66666667%;
}
@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333333%;
            flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-sm-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66666667%;
            flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-sm-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333333%;
            flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-sm-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66666667%;
            flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-sm-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333333%;
            flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-sm-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66666667%;
            flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-sm-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333333%;
            flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-sm-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66666667%;
            flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-sm-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1;
  }
  .order-sm-last {
    -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
            order: 13;
  }
  .order-sm-0 {
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0;
  }
  .order-sm-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }
  .order-sm-2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
  .order-sm-3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
  }
  .order-sm-4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4;
  }
  .order-sm-5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5;
  }
  .order-sm-6 {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6;
  }
  .order-sm-7 {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7;
  }
  .order-sm-8 {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8;
  }
  .order-sm-9 {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9;
  }
  .order-sm-10 {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10;
  }
  .order-sm-11 {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11;
  }
  .order-sm-12 {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333333%;
            flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-md-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66666667%;
            flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-md-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333333%;
            flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-md-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66666667%;
            flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-md-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333333%;
            flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-md-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66666667%;
            flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-md-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333333%;
            flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-md-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66666667%;
            flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-md-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1;
  }
  .order-md-last {
    -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
            order: 13;
  }
  .order-md-0 {
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0;
  }
  .order-md-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }
  .order-md-2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
  .order-md-3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
  }
  .order-md-4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4;
  }
  .order-md-5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5;
  }
  .order-md-6 {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6;
  }
  .order-md-7 {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7;
  }
  .order-md-8 {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8;
  }
  .order-md-9 {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9;
  }
  .order-md-10 {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10;
  }
  .order-md-11 {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11;
  }
  .order-md-12 {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333333%;
            flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-lg-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66666667%;
            flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-lg-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333333%;
            flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-lg-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66666667%;
            flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-lg-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333333%;
            flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-lg-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66666667%;
            flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-lg-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333333%;
            flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-lg-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66666667%;
            flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-lg-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1;
  }
  .order-lg-last {
    -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
            order: 13;
  }
  .order-lg-0 {
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0;
  }
  .order-lg-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }
  .order-lg-2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
  .order-lg-3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
  }
  .order-lg-4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4;
  }
  .order-lg-5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5;
  }
  .order-lg-6 {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6;
  }
  .order-lg-7 {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7;
  }
  .order-lg-8 {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8;
  }
  .order-lg-9 {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9;
  }
  .order-lg-10 {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10;
  }
  .order-lg-11 {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11;
  }
  .order-lg-12 {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333333%;
            flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xl-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66666667%;
            flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xl-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333333%;
            flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xl-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66666667%;
            flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xl-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333333%;
            flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xl-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66666667%;
            flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xl-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333333%;
            flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xl-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66666667%;
            flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xl-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1;
  }
  .order-xl-last {
    -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
            order: 13;
  }
  .order-xl-0 {
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0;
  }
  .order-xl-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }
  .order-xl-2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
  .order-xl-3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
  }
  .order-xl-4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4;
  }
  .order-xl-5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5;
  }
  .order-xl-6 {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6;
  }
  .order-xl-7 {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7;
  }
  .order-xl-8 {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8;
  }
  .order-xl-9 {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9;
  }
  .order-xl-10 {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10;
  }
  .order-xl-11 {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11;
  }
  .order-xl-12 {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #dcdedd;
  background-color: #181e21;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #3d464b;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #3d464b;
}
.table tbody + tbody {
  border-top: 2px solid #3d464b;
}
.table-sm th,
.table-sm td {
  padding: 0.3rem;
}
.table-bordered {
  border: 1px solid #3d464b;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #3d464b;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}
.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: #13171a;
}
.table-hover tbody tr:hover {
  color: #f2f4f3;
  background-color: #1b2125;
}
.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b5b8b8;
}
.table-hover .table-primary:hover {
  background-color: #818586;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #818586;
}
.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #b9bdbe;
}
.table-hover .table-secondary:hover {
  background-color: #888e90;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #888e90;
}
.table-success,
.table-success > th,
.table-success > td {
  background-color: #b6d9ce;
}
.table-hover .table-success:hover {
  background-color: #82c2ae;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #82c2ae;
}
.table-info,
.table-info > th,
.table-info > td {
  background-color: #c3dce2;
}
.table-hover .table-info:hover {
  background-color: #9bc7d3;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #9bc7d3;
}
.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #f6f3d2;
}
.table-hover .table-warning:hover {
  background-color: #f9f2b5;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #f9f2b5;
}
.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #edc1c1;
}
.table-hover .table-danger:hover {
  background-color: #e99596;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #e99596;
}
.table-light,
.table-light > th,
.table-light > td {
  background-color: #eceeed;
}
.table-hover .table-light:hover {
  background-color: #e7e9e8;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #e7e9e8;
}
.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #b5b8b8;
}
.table-hover .table-dark:hover {
  background-color: #818586;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #818586;
}
.table-active,
.table-active > th,
.table-active > td {
  background-color: #1b2125;
}
.table-hover .table-active:hover {
  background-color: #101416;
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: #101416;
}
.table .thead-dark th {
  color: #dcdedd;
  background-color: #181e21;
  border-color: #3d464b;
}
.table .thead-light th {
  color: #78cbe4;
  background-color: #13171a;
  border-color: #3d464b;
}
.table-dark {
  color: #dcdedd;
  background-color: #181e21;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #3d464b;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: #13171a;
}
.table-dark.table-hover tbody tr:hover {
  color: #f2f4f3;
  background-color: #1b2125;
}
@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}
.form-control {
  display: block;
  width: 100%;
  height: calc((1.5 * 1em) + (0.375rem * 2) + 2px);
  padding: 0.375rem 0.75rem;
  font-family: Roboto, "Helvetica Neue", "Segoe UI", "sans-serif";
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #dcdedd;
  background-color: #13171a;
  background-clip: padding-box;
  border: 1px solid #181e21;
  border-radius: 0.25rem;
  -webkit-box-shadow: 2px 3px 1px #13171a;
          box-shadow: 2px 3px 1px #13171a;
  -webkit-transition: border-color 0.35s ease-in-out, -webkit-box-shadow 0.35s ease-in-out;
  transition: border-color 0.35s ease-in-out, -webkit-box-shadow 0.35s ease-in-out;
  transition: border-color 0.35s ease-in-out, box-shadow 0.35s ease-in-out;
  transition: border-color 0.35s ease-in-out, box-shadow 0.35s ease-in-out, -webkit-box-shadow 0.35s ease-in-out;
}
@media screen and (prefers-reduced-motion: reduce) {
  .form-control {
    -webkit-transition: none;
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #dcdedd;
  background-color: #13171a;
  border-color: #78cbe4;
  outline: 0;
  -webkit-box-shadow: 2px 3px 1px #13171a, 4px 5px 2px #13171a;
          box-shadow: 2px 3px 1px #13171a, 4px 5px 2px #13171a;
}
.form-control::-webkit-input-placeholder {
  color: #c7c8c7;
  opacity: 1;
}
.form-control::-moz-placeholder {
  color: #c7c8c7;
  opacity: 1;
}
.form-control::-ms-input-placeholder {
  color: #c7c8c7;
  opacity: 1;
}
.form-control::placeholder {
  color: #c7c8c7;
  opacity: 1;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #273034;
  opacity: 1;
}
select.form-control:focus::-ms-value {
  color: #dcdedd;
  background-color: #13171a;
}
.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}
.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}
.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 17.5px;
  line-height: 1.5;
}
.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 12.25px;
  line-height: 1.5;
}
.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #dcdedd;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}
.form-control-sm {
  height: calc((1.5 * 1em) + (0.25rem * 2) + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 12.25px;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.form-control-lg {
  height: calc((1.5 * 1em) + (0.5rem * 2) + 2px);
  padding: 0.5rem 1rem;
  font-size: 17.5px;
  line-height: 1.5;
  border-radius: 0.3rem;
}
select.form-control[size],
select.form-control[multiple] {
  height: auto;
}
textarea.form-control {
  height: auto;
}
.form-group {
  margin-bottom: 1rem;
}
.form-text {
  display: block;
  margin-top: 0.25rem;
}
.form-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}
.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}
.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label {
  color: #c7c8c7;
}
.form-check-label {
  margin-bottom: 0;
}
.form-check-inline {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}
.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #1a936f;
}
.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 12.25px;
  line-height: 1.5;
  color: #f2f4f3;
  background-color: rgba(26, 147, 111, 0.9);
  border-radius: 0.25rem;
}
.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: #1a936f;
}
.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
  border-color: #1a936f;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(26, 147, 111, 0.25);
          box-shadow: 0 0 0 0.2rem rgba(26, 147, 111, 0.25);
}
.was-validated .form-control:valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip,
.form-control.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .custom-select:valid,
.custom-select.is-valid {
  border-color: #1a936f;
}
.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus {
  border-color: #1a936f;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(26, 147, 111, 0.25);
          box-shadow: 0 0 0 0.2rem rgba(26, 147, 111, 0.25);
}
.was-validated .custom-select:valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-feedback,
.was-validated .custom-select:valid ~ .valid-tooltip,
.custom-select.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .form-control-file:valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: #1a936f;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .custom-control-input:valid ~ .custom-control-label,
.custom-control-input.is-valid ~ .custom-control-label {
  color: #1a936f;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before,
.custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #1a936f;
}
.was-validated .custom-control-input:valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before,
.custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #22be90;
  background-color: #22be90;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before,
.custom-control-input.is-valid:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(26, 147, 111, 0.25);
          box-shadow: 0 0 0 0.2rem rgba(26, 147, 111, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before,
.custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #1a936f;
}
.was-validated .custom-file-input:valid ~ .custom-file-label,
.custom-file-input.is-valid ~ .custom-file-label {
  border-color: #1a936f;
}
.was-validated .custom-file-input:valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label,
.custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #1a936f;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(26, 147, 111, 0.25);
          box-shadow: 0 0 0 0.2rem rgba(26, 147, 111, 0.25);
}
.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #E03E40;
}
.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 12.25px;
  line-height: 1.5;
  color: #f2f4f3;
  background-color: rgba(224, 62, 64, 0.9);
  border-radius: 0.25rem;
}
.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: #E03E40;
}
.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  border-color: #E03E40;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(224, 62, 64, 0.25);
          box-shadow: 0 0 0 0.2rem rgba(224, 62, 64, 0.25);
}
.was-validated .form-control:invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip,
.form-control.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .custom-select:invalid,
.custom-select.is-invalid {
  border-color: #E03E40;
}
.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: #E03E40;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(224, 62, 64, 0.25);
          box-shadow: 0 0 0 0.2rem rgba(224, 62, 64, 0.25);
}
.was-validated .custom-select:invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-tooltip,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .form-control-file:invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
  color: #E03E40;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label,
.custom-control-input.is-invalid ~ .custom-control-label {
  color: #E03E40;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before,
.custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #E03E40;
}
.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before,
.custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e76a6b;
  background-color: #e76a6b;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(224, 62, 64, 0.25);
          box-shadow: 0 0 0 0.2rem rgba(224, 62, 64, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #E03E40;
}
.was-validated .custom-file-input:invalid ~ .custom-file-label,
.custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #E03E40;
}
.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label,
.custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #E03E40;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(224, 62, 64, 0.25);
          box-shadow: 0 0 0 0.2rem rgba(224, 62, 64, 0.25);
}
.form-inline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}
.btn {
  display: inline-block;
  font-family: Roboto, "Helvetica Neue", "Segoe UI", "sans-serif";
  font-weight: 400;
  color: #dcdedd;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  line-height: 1.5;
  border-radius: 0.25rem;
  -webkit-transition: color 0.35s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.35s ease-in-out;
  transition: color 0.35s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.35s ease-in-out;
  transition: color 0.35s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.35s ease-in-out;
  transition: color 0.35s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.35s ease-in-out, -webkit-box-shadow 0.35s ease-in-out;
}
@media screen and (prefers-reduced-motion: reduce) {
  .btn {
    -webkit-transition: none;
    transition: none;
  }
}
.btn:hover {
  color: #dcdedd;
  text-decoration: none;
}
.btn:focus,
.btn.focus {
  outline: 0;
  -webkit-box-shadow: 4px 5px 2px #13171a;
          box-shadow: 4px 5px 2px #13171a;
}
.btn.disabled,
.btn:disabled {
  opacity: 0.65;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.btn:not(:disabled):not(.disabled):active,
.btn:not(:disabled):not(.disabled).active {
  -webkit-box-shadow: 4px 5px 2px #13171a;
          box-shadow: 4px 5px 2px #13171a;
}
.btn:not(:disabled):not(.disabled):active:focus,
.btn:not(:disabled):not(.disabled).active:focus {
  -webkit-box-shadow: 4px 5px 2px #13171a, 4px 5px 2px #13171a;
          box-shadow: 4px 5px 2px #13171a, 4px 5px 2px #13171a;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}
.btn-primary {
  color: #f2f4f3;
  background-color: #181e21;
  border-color: #181e21;
  -webkit-box-shadow: 2px 3px 1px #13171a;
          box-shadow: 2px 3px 1px #13171a;
}
.btn-primary:hover {
  color: #f2f4f3;
  background-color: #080a0b;
  border-color: #030303;
}
.btn-primary:focus,
.btn-primary.focus {
  -webkit-box-shadow: 2px 3px 1px #13171a, 0 0 0 0.2rem rgba(57, 62, 65, 0.5);
          box-shadow: 2px 3px 1px #13171a, 0 0 0 0.2rem rgba(57, 62, 65, 0.5);
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: #f2f4f3;
  background-color: #181e21;
  border-color: #181e21;
}
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #f2f4f3;
  background-color: #030303;
  border-color: #000000;
}
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  -webkit-box-shadow: 4px 5px 2px #13171a, 0 0 0 0.2rem rgba(57, 62, 65, 0.5);
          box-shadow: 4px 5px 2px #13171a, 0 0 0 0.2rem rgba(57, 62, 65, 0.5);
}
.btn-secondary {
  color: #f2f4f3;
  background-color: #273034;
  border-color: #273034;
  -webkit-box-shadow: 2px 3px 1px #13171a;
          box-shadow: 2px 3px 1px #13171a;
}
.btn-secondary:hover {
  color: #f2f4f3;
  background-color: #171c1e;
  border-color: #111517;
}
.btn-secondary:focus,
.btn-secondary.focus {
  -webkit-box-shadow: 2px 3px 1px #13171a, 0 0 0 0.2rem rgba(69, 77, 81, 0.5);
          box-shadow: 2px 3px 1px #13171a, 0 0 0 0.2rem rgba(69, 77, 81, 0.5);
}
.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #f2f4f3;
  background-color: #273034;
  border-color: #273034;
}
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #f2f4f3;
  background-color: #111517;
  border-color: #0c0e10;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  -webkit-box-shadow: 4px 5px 2px #13171a, 0 0 0 0.2rem rgba(69, 77, 81, 0.5);
          box-shadow: 4px 5px 2px #13171a, 0 0 0 0.2rem rgba(69, 77, 81, 0.5);
}
.btn-success {
  color: #f2f4f3;
  background-color: #1a936f;
  border-color: #1a936f;
  -webkit-box-shadow: 2px 3px 1px #13171a;
          box-shadow: 2px 3px 1px #13171a;
}
.btn-success:hover {
  color: #f2f4f3;
  background-color: #147256;
  border-color: #12684e;
}
.btn-success:focus,
.btn-success.focus {
  -webkit-box-shadow: 2px 3px 1px #13171a, 0 0 0 0.2rem rgba(58, 162, 131, 0.5);
          box-shadow: 2px 3px 1px #13171a, 0 0 0 0.2rem rgba(58, 162, 131, 0.5);
}
.btn-success.disabled,
.btn-success:disabled {
  color: #f2f4f3;
  background-color: #1a936f;
  border-color: #1a936f;
}
.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #f2f4f3;
  background-color: #12684e;
  border-color: #105d46;
}
.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  -webkit-box-shadow: 4px 5px 2px #13171a, 0 0 0 0.2rem rgba(58, 162, 131, 0.5);
          box-shadow: 4px 5px 2px #13171a, 0 0 0 0.2rem rgba(58, 162, 131, 0.5);
}
.btn-info {
  color: #f2f4f3;
  background-color: #4b9eb6;
  border-color: #4b9eb6;
  -webkit-box-shadow: 2px 3px 1px #13171a;
          box-shadow: 2px 3px 1px #13171a;
}
.btn-info:hover {
  color: #f2f4f3;
  background-color: #3f879c;
  border-color: #3b7f93;
}
.btn-info:focus,
.btn-info.focus {
  -webkit-box-shadow: 2px 3px 1px #13171a, 0 0 0 0.2rem rgba(100, 171, 191, 0.5);
          box-shadow: 2px 3px 1px #13171a, 0 0 0 0.2rem rgba(100, 171, 191, 0.5);
}
.btn-info.disabled,
.btn-info:disabled {
  color: #f2f4f3;
  background-color: #4b9eb6;
  border-color: #4b9eb6;
}
.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #f2f4f3;
  background-color: #3b7f93;
  border-color: #387789;
}
.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  -webkit-box-shadow: 4px 5px 2px #13171a, 0 0 0 0.2rem rgba(100, 171, 191, 0.5);
          box-shadow: 4px 5px 2px #13171a, 0 0 0 0.2rem rgba(100, 171, 191, 0.5);
}
.btn-warning {
  color: #13171a;
  background-color: #fff07c;
  border-color: #fff07c;
  -webkit-box-shadow: 2px 3px 1px #13171a;
          box-shadow: 2px 3px 1px #13171a;
}
.btn-warning:hover {
  color: #13171a;
  background-color: #ffec56;
  border-color: #ffea49;
}
.btn-warning:focus,
.btn-warning.focus {
  -webkit-box-shadow: 2px 3px 1px #13171a, 0 0 0 0.2rem rgba(220, 207, 109, 0.5);
          box-shadow: 2px 3px 1px #13171a, 0 0 0 0.2rem rgba(220, 207, 109, 0.5);
}
.btn-warning.disabled,
.btn-warning:disabled {
  color: #13171a;
  background-color: #fff07c;
  border-color: #fff07c;
}
.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #13171a;
  background-color: #ffea49;
  border-color: #ffe93c;
}
.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  -webkit-box-shadow: 4px 5px 2px #13171a, 0 0 0 0.2rem rgba(220, 207, 109, 0.5);
          box-shadow: 4px 5px 2px #13171a, 0 0 0 0.2rem rgba(220, 207, 109, 0.5);
}
.btn-danger {
  color: #f2f4f3;
  background-color: #E03E40;
  border-color: #E03E40;
  -webkit-box-shadow: 2px 3px 1px #13171a;
          box-shadow: 2px 3px 1px #13171a;
}
.btn-danger:hover {
  color: #f2f4f3;
  background-color: #d52224;
  border-color: #ca2123;
}
.btn-danger:focus,
.btn-danger.focus {
  -webkit-box-shadow: 2px 3px 1px #13171a, 0 0 0 0.2rem rgba(227, 89, 91, 0.5);
          box-shadow: 2px 3px 1px #13171a, 0 0 0 0.2rem rgba(227, 89, 91, 0.5);
}
.btn-danger.disabled,
.btn-danger:disabled {
  color: #f2f4f3;
  background-color: #E03E40;
  border-color: #E03E40;
}
.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #f2f4f3;
  background-color: #ca2123;
  border-color: #bf1f21;
}
.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  -webkit-box-shadow: 4px 5px 2px #13171a, 0 0 0 0.2rem rgba(227, 89, 91, 0.5);
          box-shadow: 4px 5px 2px #13171a, 0 0 0 0.2rem rgba(227, 89, 91, 0.5);
}
.btn-outline-primary {
  color: #181e21;
  border-color: #181e21;
}
.btn-outline-primary #hover {
  color: #f2f4f3;
  background-color: #181e21;
  border-color: #181e21;
}
.btn-outline-primary:focus,
.btn-outline-primary.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(24, 30, 33, 0.5);
          box-shadow: 0 0 0 0.2rem rgba(24, 30, 33, 0.5);
}
.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #181e21;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #f2f4f3;
  background-color: #181e21;
  border-color: #181e21;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  -webkit-box-shadow: 4px 5px 2px #13171a, 0 0 0 0.2rem rgba(24, 30, 33, 0.5);
          box-shadow: 4px 5px 2px #13171a, 0 0 0 0.2rem rgba(24, 30, 33, 0.5);
}
.btn-outline-secondary {
  color: #273034;
  border-color: #273034;
}
.btn-outline-secondary #hover {
  color: #f2f4f3;
  background-color: #273034;
  border-color: #273034;
}
.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(39, 48, 52, 0.5);
          box-shadow: 0 0 0 0.2rem rgba(39, 48, 52, 0.5);
}
.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #273034;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #f2f4f3;
  background-color: #273034;
  border-color: #273034;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  -webkit-box-shadow: 4px 5px 2px #13171a, 0 0 0 0.2rem rgba(39, 48, 52, 0.5);
          box-shadow: 4px 5px 2px #13171a, 0 0 0 0.2rem rgba(39, 48, 52, 0.5);
}
.btn-outline-success {
  color: #1a936f;
  border-color: #1a936f;
}
.btn-outline-success #hover {
  color: #f2f4f3;
  background-color: #1a936f;
  border-color: #1a936f;
}
.btn-outline-success:focus,
.btn-outline-success.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(26, 147, 111, 0.5);
          box-shadow: 0 0 0 0.2rem rgba(26, 147, 111, 0.5);
}
.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #1a936f;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #f2f4f3;
  background-color: #1a936f;
  border-color: #1a936f;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  -webkit-box-shadow: 4px 5px 2px #13171a, 0 0 0 0.2rem rgba(26, 147, 111, 0.5);
          box-shadow: 4px 5px 2px #13171a, 0 0 0 0.2rem rgba(26, 147, 111, 0.5);
}
.btn-outline-info {
  color: #4b9eb6;
  border-color: #4b9eb6;
}
.btn-outline-info #hover {
  color: #f2f4f3;
  background-color: #4b9eb6;
  border-color: #4b9eb6;
}
.btn-outline-info:focus,
.btn-outline-info.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(75, 158, 182, 0.5);
          box-shadow: 0 0 0 0.2rem rgba(75, 158, 182, 0.5);
}
.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #4b9eb6;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #f2f4f3;
  background-color: #4b9eb6;
  border-color: #4b9eb6;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  -webkit-box-shadow: 4px 5px 2px #13171a, 0 0 0 0.2rem rgba(75, 158, 182, 0.5);
          box-shadow: 4px 5px 2px #13171a, 0 0 0 0.2rem rgba(75, 158, 182, 0.5);
}
.btn-outline-warning {
  color: #fff07c;
  border-color: #fff07c;
}
.btn-outline-warning #hover {
  color: #13171a;
  background-color: #fff07c;
  border-color: #fff07c;
}
.btn-outline-warning:focus,
.btn-outline-warning.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 240, 124, 0.5);
          box-shadow: 0 0 0 0.2rem rgba(255, 240, 124, 0.5);
}
.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #fff07c;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #13171a;
  background-color: #fff07c;
  border-color: #fff07c;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  -webkit-box-shadow: 4px 5px 2px #13171a, 0 0 0 0.2rem rgba(255, 240, 124, 0.5);
          box-shadow: 4px 5px 2px #13171a, 0 0 0 0.2rem rgba(255, 240, 124, 0.5);
}
.btn-outline-danger {
  color: #E03E40;
  border-color: #E03E40;
}
.btn-outline-danger #hover {
  color: #f2f4f3;
  background-color: #E03E40;
  border-color: #E03E40;
}
.btn-outline-danger:focus,
.btn-outline-danger.focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(224, 62, 64, 0.5);
          box-shadow: 0 0 0 0.2rem rgba(224, 62, 64, 0.5);
}
.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #E03E40;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #f2f4f3;
  background-color: #E03E40;
  border-color: #E03E40;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  -webkit-box-shadow: 4px 5px 2px #13171a, 0 0 0 0.2rem rgba(224, 62, 64, 0.5);
          box-shadow: 4px 5px 2px #13171a, 0 0 0 0.2rem rgba(224, 62, 64, 0.5);
}
.btn-link {
  font-weight: 400;
  color: #78cbe4;
  text-decoration: none;
}
.btn-link:hover {
  color: #96d6ea;
  text-decoration: underline;
}
.btn-link:focus,
.btn-link.focus {
  text-decoration: underline;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.btn-link:disabled,
.btn-link.disabled {
  color: #c7c8c7;
  pointer-events: none;
}
.btn-lg,
.btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 17.5px;
  line-height: 1.5;
  border-radius: 0.3rem;
}
.btn-sm,
.btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 12.25px;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}
input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}
.fade {
  -webkit-transition: opacity 0.45s ease-in-out;
  transition: opacity 0.45s ease-in-out;
}
@media screen and (prefers-reduced-motion: reduce) {
  .fade {
    -webkit-transition: none;
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}
.collapse:not(.show) {
  display: none;
}
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition: height 0.5s ease-in-out;
  transition: height 0.5s ease-in-out;
}
@media screen and (prefers-reduced-motion: reduce) {
  .collapsing {
    -webkit-transition: none;
    transition: none;
  }
}
.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}
.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 14px;
  color: #f2f4f3;
  text-align: left;
  list-style: none;
  background-color: #1b2125;
  background-clip: padding-box;
  border: 1px solid #1d2428;
  border-radius: 0.25rem;
  -webkit-box-shadow: 2px 3px 1px #13171a;
          box-shadow: 2px 3px 1px #13171a;
}
.dropdown-menuxs-left {
  right: auto;
  left: 0;
}
.dropdown-menuxs-right {
  right: 0;
  left: auto;
}
@media (min-width: 576px) {
  .dropdown-menusm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menusm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menumd-left {
    right: auto;
    left: 0;
  }
  .dropdown-menumd-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menulg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menulg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menuxl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menuxl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}
.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}
.dropdown-menu[x-placement^="top"],
.dropdown-menu[x-placement^="right"],
.dropdown-menu[x-placement^="bottom"],
.dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}
.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #3d464b;
}
.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #f2f4f3;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover,
.dropdown-item:focus {
  color: #dcdedd;
  text-decoration: none;
  background-color: #1d2428;
}
.dropdown-item.active,
.dropdown-item:active {
  color: #78cbe4;
  text-decoration: none;
  background-color: #181e21;
}
.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #c7c8c7;
  background-color: transparent;
  pointer-events: none;
}
.dropdown-menu.show {
  display: block;
}
.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 12.25px;
  color: #5bc0de;
  white-space: nowrap;
}
.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #f2f4f3;
}
.btn-group,
.btn-group-vertical {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
  -webkit-box-shadow: 2px 3px 1px #13171a;
          box-shadow: 2px 3px 1px #13171a;
}
.btn-group > .btn:focus,
.btn-group-vertical > .btn:focus,
.btn-group > .btn:active,
.btn-group-vertical > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn.active {
  z-index: 1;
  -webkit-box-shadow: 2px 3px 1px #13171a;
          box-shadow: 2px 3px 1px #13171a;
}
.btn-toolbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}
.btn-sm + .dropdown-toggle-split,
.btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}
.btn-lg + .dropdown-toggle-split,
.btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}
.btn-group.show .dropdown-toggle {
  -webkit-box-shadow: 4px 5px 2px #13171a;
          box-shadow: 4px 5px 2px #13171a;
}
.btn-group.show .dropdown-toggle.btn-link {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.btn-group-vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.input-group {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control-plaintext + .form-control,
.input-group > .custom-select + .form-control,
.input-group > .custom-file + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .custom-select + .custom-select,
.input-group > .custom-file + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group-prepend,
.input-group-append {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-append .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-append .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}
.input-group-prepend {
  margin-right: -1px;
}
.input-group-append {
  margin-left: -1px;
}
.input-group-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #dcdedd;
  text-align: center;
  white-space: nowrap;
  background-color: #1d2428;
  border: 1px solid #181e21;
  border-radius: 0.25rem;
  -webkit-box-shadow: 2px 3px 1px #13171a;
          box-shadow: 2px 3px 1px #13171a;
}
.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}
.input-group-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 4px 8px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #dcdedd;
  text-align: center;
  white-space: nowrap;
  background-color: #1d2428;
  border: 1px solid #181e21;
  border-radius: 0.25rem;
  -webkit-box-shadow: 2px 3px 1px #13171a;
          box-shadow: 2px 3px 1px #13171a;
}
.input-group-icon svg {
  margin-right: auto;
  margin-left: auto;
}
.input-group-icon input[type="radio"],
.input-group-icon input[type="checkbox"] {
  margin-top: 0;
}
.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc((1.5 * 1em) + (0.5rem * 2) + 2px);
}
.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 17.5px;
  line-height: 1.5;
  border-radius: 0.3rem;
}
.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc((1.5 * 1em) + (0.25rem * 2) + 2px);
}
.input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 12.25px;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}
.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.custom-control {
  position: relative;
  display: block;
  min-height: 21px;
  padding-left: 1.5rem;
}
.custom-control-inline {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 1rem;
}
.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #78cbe4;
  border-color: #181e21;
  background-color: #181e21;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.custom-control-input:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 4px 5px 2px #13171a;
          box-shadow: 4px 5px 2px #13171a;
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #78cbe4;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #78cbe4;
  background-color: #637c88;
  border-color: #637c88;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.custom-control-input[disabled] ~ .custom-control-label {
  color: #c7c8c7;
}
.custom-control-input[disabled] ~ .custom-control-label::before {
  background-color: #273034;
}
.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 10px;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #13171a;
  border: #273034 solid 1px;
  -webkit-box-shadow: 2px 3px 1px #13171a;
          box-shadow: 2px 3px 1px #13171a;
}
.custom-control-label::after {
  position: absolute;
  top: 10px;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}
.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2378cbe4' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #181e21;
  background-color: #181e21;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%2378cbe4' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: #273034;
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: #273034;
}
.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2378cbe4'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: #273034;
}
.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(((14px * 1.5 - 1rem) / 2) + (1px * 2));
  left: calc((-(1.75rem + 0.5rem)) + (1px * 2));
  width: calc(1rem - 1px * 4);
  height: calc(1rem - 1px * 4);
  background-color: #273034;
  border-radius: 0.5rem;
  -webkit-transition: background-color 0.35s ease-in-out, border-color 0.35s ease-in-out, -webkit-transform 0.15s ease-in-out, -webkit-box-shadow 0.35s ease-in-out;
  transition: background-color 0.35s ease-in-out, border-color 0.35s ease-in-out, -webkit-transform 0.15s ease-in-out, -webkit-box-shadow 0.35s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.35s ease-in-out, border-color 0.35s ease-in-out, box-shadow 0.35s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.35s ease-in-out, border-color 0.35s ease-in-out, box-shadow 0.35s ease-in-out, -webkit-transform 0.15s ease-in-out, -webkit-box-shadow 0.35s ease-in-out;
}
@media screen and (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    -webkit-transition: none;
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #13171a;
  -webkit-transform: translateX(0.75rem);
          transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: #273034;
}
.custom-select {
  display: inline-block;
  width: 100%;
  height: calc((1.5 * 1em) + (0.375rem * 2) + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-family: Roboto, "Helvetica Neue", "Segoe UI", "sans-serif";
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #dcdedd;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%235bc0de' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center / 8px 10px;
  background-color: #13171a;
  border: 1px solid #181e21;
  border-radius: 0.25rem;
  -webkit-box-shadow: 2px 3px 1px #13171a;
          box-shadow: 2px 3px 1px #13171a;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
.custom-select:focus {
  border-color: #78cbe4;
  outline: 0;
  -webkit-box-shadow: 2px 3px 1px #13171a, 4px 5px 2px #13171a;
          box-shadow: 2px 3px 1px #13171a, 4px 5px 2px #13171a;
}
.custom-select:focus::-ms-value {
  color: #dcdedd;
  background-color: #13171a;
}
.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #c7c8c7;
  background-color: #273034;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select-sm {
  height: calc((1.5 * 1em) + (0.25rem * 2) + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 12.25px;
}
.custom-select-lg {
  height: calc((1.5 * 1em) + (0.5rem * 2) + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 17.5px;
}
.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc((1.5 * 1em) + (0.375rem * 2) + 2px);
  margin-bottom: 0;
}
.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc((1.5 * 1em) + (0.375rem * 2) + 2px);
  margin: 0;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #78cbe4;
  -webkit-box-shadow: 4px 5px 2px #13171a;
          box-shadow: 4px 5px 2px #13171a;
}
.custom-file-input[disabled] ~ .custom-file-label {
  background-color: #273034;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}
.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc((1.5 * 1em) + (0.375rem * 2) + 2px);
  padding: 0.375rem 0.75rem;
  font-family: Roboto, "Helvetica Neue", "Segoe UI", "sans-serif";
  font-weight: 400;
  line-height: 1.5;
  color: #dcdedd;
  background-color: #13171a;
  border: 1px solid #181e21;
  border-radius: 0.25rem;
  -webkit-box-shadow: 2px 3px 1px #13171a;
          box-shadow: 2px 3px 1px #13171a;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc((1.5 * 1em) + (0.375rem * 2));
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #dcdedd;
  content: "Browse";
  background-color: #1d2428;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}
.custom-range {
  width: 100%;
  height: calc(1rem + (0.2rem * 2));
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
.custom-range:focus {
  outline: none;
}
.custom-range:focus::-webkit-slider-thumb {
  -webkit-box-shadow: 4px 5px 2px #13171a;
          box-shadow: 4px 5px 2px #13171a;
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 4px 5px 2px #13171a;
}
.custom-range:focus::-ms-thumb {
  box-shadow: 4px 5px 2px #13171a;
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #181e21;
  border: 0;
  border-radius: 1rem;
  -webkit-box-shadow: 2px 3px 1px #13171a;
          box-shadow: 2px 3px 1px #13171a;
  -webkit-transition: background-color 0.35s ease-in-out, border-color 0.35s ease-in-out, -webkit-box-shadow 0.35s ease-in-out;
  transition: background-color 0.35s ease-in-out, border-color 0.35s ease-in-out, -webkit-box-shadow 0.35s ease-in-out;
  transition: background-color 0.35s ease-in-out, border-color 0.35s ease-in-out, box-shadow 0.35s ease-in-out;
  transition: background-color 0.35s ease-in-out, border-color 0.35s ease-in-out, box-shadow 0.35s ease-in-out, -webkit-box-shadow 0.35s ease-in-out;
  -webkit-appearance: none;
          appearance: none;
}
@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #637c88;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #13171a;
  border-color: transparent;
  border-radius: 1rem;
  -webkit-box-shadow: 2px 3px 1px #13171a;
          box-shadow: 2px 3px 1px #13171a;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #181e21;
  border: 0;
  border-radius: 1rem;
  box-shadow: 2px 3px 1px #13171a;
  -webkit-transition: background-color 0.35s ease-in-out, border-color 0.35s ease-in-out, -webkit-box-shadow 0.35s ease-in-out;
  transition: background-color 0.35s ease-in-out, border-color 0.35s ease-in-out, -webkit-box-shadow 0.35s ease-in-out;
  transition: background-color 0.35s ease-in-out, border-color 0.35s ease-in-out, box-shadow 0.35s ease-in-out;
  transition: background-color 0.35s ease-in-out, border-color 0.35s ease-in-out, box-shadow 0.35s ease-in-out, -webkit-box-shadow 0.35s ease-in-out;
  -moz-appearance: none;
       appearance: none;
}
@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    -webkit-transition: none;
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #637c88;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #13171a;
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: 2px 3px 1px #13171a;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #181e21;
  border: 0;
  border-radius: 1rem;
  box-shadow: 2px 3px 1px #13171a;
  -webkit-transition: background-color 0.35s ease-in-out, border-color 0.35s ease-in-out, -webkit-box-shadow 0.35s ease-in-out;
  transition: background-color 0.35s ease-in-out, border-color 0.35s ease-in-out, -webkit-box-shadow 0.35s ease-in-out;
  transition: background-color 0.35s ease-in-out, border-color 0.35s ease-in-out, box-shadow 0.35s ease-in-out;
  transition: background-color 0.35s ease-in-out, border-color 0.35s ease-in-out, box-shadow 0.35s ease-in-out, -webkit-box-shadow 0.35s ease-in-out;
  appearance: none;
}
@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    -webkit-transition: none;
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #637c88;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
  box-shadow: 2px 3px 1px #13171a;
}
.custom-range::-ms-fill-lower {
  background-color: #13171a;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #13171a;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #273034;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #273034;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #273034;
}
.custom-control-label::before,
.custom-file-label,
.custom-select {
  -webkit-transition: background-color 0.35s ease-in-out, border-color 0.35s ease-in-out, -webkit-box-shadow 0.35s ease-in-out;
  transition: background-color 0.35s ease-in-out, border-color 0.35s ease-in-out, -webkit-box-shadow 0.35s ease-in-out;
  transition: background-color 0.35s ease-in-out, border-color 0.35s ease-in-out, box-shadow 0.35s ease-in-out;
  transition: background-color 0.35s ease-in-out, border-color 0.35s ease-in-out, box-shadow 0.35s ease-in-out, -webkit-box-shadow 0.35s ease-in-out;
}
@media screen and (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    -webkit-transition: none;
    transition: none;
  }
}
.nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover,
.nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #c7c8c7;
  pointer-events: none;
  cursor: default;
}
.nav-tabs {
  border-bottom: 1px solid #273034;
}
.nav-tabs .nav-item {
  margin-bottom: -1px;
}
.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #273034 #273034 #273034;
}
.nav-tabs .nav-link.disabled {
  color: #c7c8c7;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #5bc0de;
  background-color: #1d2428;
  border-color: #273034 #273034 #1d2428;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.nav-pills .nav-link {
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #78cbe4;
  background-color: #181e21;
}
.nav-fill .nav-item {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  text-align: center;
}
.nav-justified .nav-item {
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  text-align: center;
}
.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}
.navbar {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar > .container,
.navbar > .container-fluid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: -2.125px;
  padding-bottom: -2.125px;
  margin-right: 1rem;
  font-size: 17.5px;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover,
.navbar-brand:focus {
  text-decoration: none;
}
.navbar-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}
.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.navbar-collapse {
  -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 17.5px;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:hover,
.navbar-toggler:focus {
  text-decoration: none;
}
.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}
@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid {
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
}
.navbar-expand .navbar-collapse {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-preferred-size: auto;
      flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-dark .navbar-brand {
  color: #5bc0de;
}
.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
  color: #5bc0de;
}
.navbar-dark .navbar-nav .nav-link {
  color: #f2f4f3;
}
.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: #dcdedd;
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: #c7c8c7;
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #5bc0de;
}
.navbar-dark .navbar-toggler {
  color: #f2f4f3;
  border-color: #273034;
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='%23f2f4f3' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: #f2f4f3;
}
.navbar-dark .navbar-text a {
  color: #5bc0de;
}
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #5bc0de;
}
.card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #1b2125;
  background-clip: border-box;
  border: 1px solid #1d2428;
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.card-body {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  padding: 1.25rem;
  color: null;
}
.card-title {
  margin-bottom: 0.75rem;
}
.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}
.card-text:last-child {
  margin-bottom: 0;
}
.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}
.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  color: null;
  background-color: #13171a;
  border-bottom: 1px solid #1d2428;
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}
.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: #13171a;
  border-top: 1px solid #1d2428;
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}
.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}
.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}
.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}
.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px);
}
.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card-deck {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
        -ms-flex: 1 0 0%;
            flex: 1 0 0%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}
.card-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap;
  }
  .card-group > .card {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 0%;
            flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}
.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
       -moz-column-count: 3;
            column-count: 3;
    -webkit-column-gap: 1.25rem;
       -moz-column-gap: 1.25rem;
            column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0;
}
.accordion > .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0;
}
.accordion > .card:first-of-type:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card .card-header {
  margin-bottom: -1px;
}
.breadcrumb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #1b2125;
  border-radius: 0.25rem;
}
.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #3d464b;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #78cbe4;
}
.pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}
.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #f2f4f3;
  background-color: #13171a;
  border: 1px solid #3d464b;
}
.page-link .pagination-icon {
  width: 14px;
  height: 14px;
  margin-top: -2px;
  fill: #f2f4f3;
  -webkit-transition: fill 0.35s ease-in-out;
  transition: fill 0.35s ease-in-out;
}
.page-link:hover {
  z-index: 2;
  color: #96d6ea;
  text-decoration: none;
  background-color: #1b2125;
  border-color: #5bc0de;
}
.page-link:hover .pagination-icon {
  fill: #96d6ea;
}
.page-link:focus {
  z-index: 2;
  outline: 0;
  -webkit-box-shadow: 4px 5px 2px #13171a;
          box-shadow: 4px 5px 2px #13171a;
}
.page-link:focus .pagination-icon {
  fill: #96d6ea;
}
.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 1;
  color: #78cbe4;
  background-color: #181e21;
  border-color: #181e21;
}
.page-item.active .page-link .pagination-icon {
  fill: #78cbe4;
}
.page-item.disabled .page-link {
  color: #c7c8c7;
  pointer-events: none;
  cursor: auto;
  background-color: #273034;
  border-color: #273034;
}
.page-item.disabled .page-link .pagination-icon {
  fill: #c7c8c7;
}
.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 17.5px;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 12.25px;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}
.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  -webkit-transition: color 0.35s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.35s ease-in-out;
  transition: color 0.35s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.35s ease-in-out;
  transition: color 0.35s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.35s ease-in-out;
  transition: color 0.35s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.35s ease-in-out, -webkit-box-shadow 0.35s ease-in-out;
}
.badge:empty {
  display: none;
}
a.badge #hover-focus {
  text-decoration: none;
}
.btn .badge {
  position: relative;
  top: -1px;
}
.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}
.badge-primary {
  color: #f2f4f3;
  background-color: #181e21;
}
.badge-primary[href]:hover,
.badge-primary[href]:focus {
  color: #f2f4f3;
  text-decoration: none;
  background-color: #030303;
}
.badge-secondary {
  color: #f2f4f3;
  background-color: #273034;
}
.badge-secondary[href]:hover,
.badge-secondary[href]:focus {
  color: #f2f4f3;
  text-decoration: none;
  background-color: #111517;
}
.badge-success {
  color: #f2f4f3;
  background-color: #1a936f;
}
.badge-success[href]:hover,
.badge-success[href]:focus {
  color: #f2f4f3;
  text-decoration: none;
  background-color: #12684e;
}
.badge-info {
  color: #f2f4f3;
  background-color: #4b9eb6;
}
.badge-info[href]:hover,
.badge-info[href]:focus {
  color: #f2f4f3;
  text-decoration: none;
  background-color: #3b7f93;
}
.badge-warning {
  color: #f2f4f3;
  background-color: #fff07c;
}
.badge-warning[href]:hover,
.badge-warning[href]:focus {
  color: #f2f4f3;
  text-decoration: none;
  background-color: #ffea49;
}
.badge-danger {
  color: #f2f4f3;
  background-color: #E03E40;
}
.badge-danger[href]:hover,
.badge-danger[href]:focus {
  color: #f2f4f3;
  text-decoration: none;
  background-color: #ca2123;
}
.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  color: null;
  background-color: #181e21;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}
.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}
.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.alert-heading {
  color: inherit;
}
.alert-link {
  font-weight: 700;
}
.alert-dismissible {
  padding-right: 23.5px;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}
.alert-primary {
  color: #f2f4f3;
  background-color: #181e21;
  border-color: #181e21;
}
.alert-primary hr {
  border-top-color: #0d1112;
}
.alert-primary .alert-link {
  color: #d6ddd9;
}
.alert-secondary {
  color: #f2f4f3;
  background-color: #273034;
  border-color: #273034;
}
.alert-secondary hr {
  border-top-color: #1c2325;
}
.alert-secondary .alert-link {
  color: #d6ddd9;
}
.alert-success {
  color: #f2f4f3;
  background-color: #1a936f;
  border-color: #1a936f;
}
.alert-success hr {
  border-top-color: #167d5f;
}
.alert-success .alert-link {
  color: #d6ddd9;
}
.alert-info {
  color: #f2f4f3;
  background-color: #4b9eb6;
  border-color: #4b9eb6;
}
.alert-info hr {
  border-top-color: #438fa5;
}
.alert-info .alert-link {
  color: #d6ddd9;
}
.alert-warning {
  color: #f2f4f3;
  background-color: #fff07c;
  border-color: #fff07c;
}
.alert-warning hr {
  border-top-color: #ffed62;
}
.alert-warning .alert-link {
  color: #d6ddd9;
}
.alert-danger {
  color: #f2f4f3;
  background-color: #E03E40;
  border-color: #E03E40;
}
.alert-danger hr {
  border-top-color: #dc282a;
}
.alert-danger .alert-link {
  color: #d6ddd9;
}
@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 10.5px;
  background-color: #13171a;
  border-radius: 0.25rem;
  -webkit-box-shadow: 2px 3px 1px #13171a;
          box-shadow: 2px 3px 1px #13171a;
}
.progress-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: #dcdedd;
  text-align: center;
  white-space: nowrap;
  background-color: #E03E40;
  -webkit-transition: width 0.6s ease;
  transition: width 0.6s ease;
}
@media screen and (prefers-reduced-motion: reduce) {
  .progress-bar {
    -webkit-transition: none;
    transition: none;
  }
}
.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(242, 244, 243, 0.15) 25%, transparent 25%, transparent 50%, rgba(242, 244, 243, 0.15) 50%, rgba(242, 244, 243, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}
.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
          animation: progress-bar-stripes 1s linear infinite;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    -webkit-animation: none;
            animation: none;
  }
}
.media {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.media-body {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.list-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}
.list-group-item-action {
  width: 100%;
  color: #dcdedd;
  text-align: inherit;
}
.list-group-item-action:hover,
.list-group-item-action:focus {
  z-index: 1;
  color: #dcdedd;
  text-decoration: none;
  background-color: #1b2125;
}
.list-group-item-action:active {
  color: #78cbe4;
  background-color: #1b2125;
}
.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  color: null;
  background-color: #181e21;
  border: 1px solid #3d464b;
}
.list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.list-group-item.disabled,
.list-group-item:disabled {
  color: #1d2428;
  pointer-events: none;
  background-color: #181e21;
}
.list-group-item.active {
  z-index: 2;
  color: #78cbe4;
  background-color: #181e21;
  border-color: #181e21;
}
.list-group-horizontalxs {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}
.list-group-horizontalxs .list-group-item {
  margin-right: -1px;
  margin-bottom: 0;
}
.list-group-horizontalxs .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontalxs .list-group-item:last-child {
  margin-right: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
@media (min-width: 576px) {
  .list-group-horizontalsm {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .list-group-horizontalsm .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontalsm .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontalsm .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}
@media (min-width: 768px) {
  .list-group-horizontalmd {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .list-group-horizontalmd .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontalmd .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontalmd .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}
@media (min-width: 992px) {
  .list-group-horizontallg {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .list-group-horizontallg .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontallg .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontallg .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontalxl {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .list-group-horizontalxl .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontalxl .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontalxl .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}
.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.list-group-flush .list-group-item:last-child {
  margin-bottom: -1px;
}
.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}
.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0;
}
.list-group-item-primary {
  color: #0c1011;
  background-color: #b5b8b8;
}
.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
  color: #0c1011;
  background-color: #a8acac;
}
.list-group-item-primary.list-group-item-action.active {
  color: #f2f4f3;
  background-color: #0c1011;
  border-color: #0c1011;
}
.list-group-item-secondary {
  color: #14191b;
  background-color: #b9bdbe;
}
.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
  color: #14191b;
  background-color: #acb1b2;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #f2f4f3;
  background-color: #14191b;
  border-color: #14191b;
}
.list-group-item-success {
  color: #0e4c3a;
  background-color: #b6d9ce;
}
.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
  color: #0e4c3a;
  background-color: #a5d0c3;
}
.list-group-item-success.list-group-item-action.active {
  color: #f2f4f3;
  background-color: #0e4c3a;
  border-color: #0e4c3a;
}
.list-group-item-info {
  color: #27525f;
  background-color: #c3dce2;
}
.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
  color: #27525f;
  background-color: #b2d2da;
}
.list-group-item-info.list-group-item-action.active {
  color: #f2f4f3;
  background-color: #27525f;
  border-color: #27525f;
}
.list-group-item-warning {
  color: #857d40;
  background-color: #f6f3d2;
}
.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
  color: #857d40;
  background-color: #f2edbd;
}
.list-group-item-warning.list-group-item-action.active {
  color: #f2f4f3;
  background-color: #857d40;
  border-color: #857d40;
}
.list-group-item-danger {
  color: #742021;
  background-color: #edc1c1;
}
.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
  color: #742021;
  background-color: #e7adad;
}
.list-group-item-danger.list-group-item-action.active {
  color: #f2f4f3;
  background-color: #742021;
  border-color: #742021;
}
.list-group-item-light {
  color: #727373;
  background-color: #eceeed;
}
.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
  color: #727373;
  background-color: #dfe2e0;
}
.list-group-item-light.list-group-item-action.active {
  color: #f2f4f3;
  background-color: #727373;
  border-color: #727373;
}
.list-group-item-dark {
  color: #0c1011;
  background-color: #b5b8b8;
}
.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
  color: #0c1011;
  background-color: #a8acac;
}
.list-group-item-dark.list-group-item-action.active {
  color: #f2f4f3;
  background-color: #0c1011;
  border-color: #0c1011;
}
.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  color: null;
  background-color: #1b2125;
  background-clip: padding-box;
  border: 1px solid #1d2428;
  -webkit-box-shadow: 4px 5px 2px #13171a;
          box-shadow: 4px 5px 2px #13171a;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}
.toast-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0.25rem 0.75rem;
  color: toast-header-color;
  background-color: #13171a;
  background-clip: padding-box;
  border-bottom: 1px solid #273034;
}
.toast-body {
  padding: 0.75rem;
}
.close {
  float: right;
  font-size: 21px;
  font-weight: 700;
  line-height: 1;
  color: #e35456;
  text-shadow: 2px 1px 1px #13171a;
  opacity: 0.5;
}
.close #hover {
  color: #e35456;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}
button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
a.close.disabled {
  pointer-events: none;
}
.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}
.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  -webkit-transition: -webkit-transform 0.35s ease-out;
  transition: -webkit-transform 0.35s ease-out;
  transition: transform 0.35s ease-out;
  transition: transform 0.35s ease-out, -webkit-transform 0.35s ease-out;
  -webkit-transform: translate(0, -50px);
          transform: translate(0, -50px);
}
@media screen and (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    -webkit-transition: none;
    transition: none;
  }
}
.modal.show .modal-dialog {
  -webkit-transform: none;
          transform: none;
}
.modal-dialog .modal-header,
.modal-dialog .modal-footer {
  background-color: #13171a;
}
.modal-dialog-scrollable {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-height: calc(100% - (0.5rem * 2));
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - (0.5rem * 2));
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  background-color: #13171a;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}
.modal-dialog-centered {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  min-height: calc(100% - (0.5rem * 2));
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - (0.5rem * 2));
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}
.modal-content {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  color: null;
  pointer-events: auto;
  background-color: #1b2125;
  background-clip: padding-box;
  border: 1px solid #1d2428;
  border-radius: 0.3rem;
  -webkit-box-shadow: 0 0.25rem 0.5rem #13171a;
          box-shadow: 0 0.25rem 0.5rem #13171a;
  outline: 0;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #13171a;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}
.modal-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #3d464b;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}
.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}
.modal-body {
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  padding: 1rem;
}
.modal-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #3d464b;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.modal-footer > :not(:first-child) {
  margin-left: 0.25rem;
}
.modal-footer > :not(:last-child) {
  margin-right: 0.25rem;
}
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - (1.75rem * 2));
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - (1.75rem * 2));
  }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2));
  }
  .modal-dialog-centered::before {
    height: calc(100vh - (1.75rem * 2));
  }
  .modal-content {
    -webkit-box-shadow: 0 0.5rem 1rem #13171a;
            box-shadow: 0 0.5rem 1rem #13171a;
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: Roboto, "Helvetica Neue", "Segoe UI", "sans-serif";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 12.25px;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}
.bs-tooltip-top,
.bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow,
.bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #181e21;
}
.bs-tooltip-right,
.bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow,
.bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #181e21;
}
.bs-tooltip-bottom,
.bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow,
.bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #181e21;
}
.bs-tooltip-left,
.bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow,
.bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #181e21;
}
.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #dcdedd;
  text-align: center;
  background-color: #181e21;
  border-radius: 0.25rem;
}
.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: Roboto, "Helvetica Neue", "Segoe UI", "sans-serif";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 12.25px;
  word-wrap: break-word;
  background-color: #181e21;
  background-clip: padding-box;
  border: 1px solid #1d2428;
  border-radius: 0.3rem;
  -webkit-box-shadow: 4px 5px 2px #13171a;
          box-shadow: 4px 5px 2px #13171a;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before,
.popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}
.bs-popover-top,
.bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow,
.bs-popover-auto[x-placement^="top"] > .arrow {
  bottom: calc((0.5rem + 1px) * -1);
}
.bs-popover-top > .arrow::before,
.bs-popover-auto[x-placement^="top"] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #1d2428;
}
.bs-popover-top > .arrow::after,
.bs-popover-auto[x-placement^="top"] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #181e21;
}
.bs-popover-right,
.bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow,
.bs-popover-auto[x-placement^="right"] > .arrow {
  left: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right > .arrow::before,
.bs-popover-auto[x-placement^="right"] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #1d2428;
}
.bs-popover-right > .arrow::after,
.bs-popover-auto[x-placement^="right"] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #181e21;
}
.bs-popover-bottom,
.bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow,
.bs-popover-auto[x-placement^="bottom"] > .arrow {
  top: calc((0.5rem + 1px) * -1);
}
.bs-popover-bottom > .arrow::before,
.bs-popover-auto[x-placement^="bottom"] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #1d2428;
}
.bs-popover-bottom > .arrow::after,
.bs-popover-auto[x-placement^="bottom"] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #181e21;
}
.bs-popover-bottom .popover-header::before,
.bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #13171a;
}
.bs-popover-left,
.bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow,
.bs-popover-auto[x-placement^="left"] > .arrow {
  right: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left > .arrow::before,
.bs-popover-auto[x-placement^="left"] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #1d2428;
}
.bs-popover-left > .arrow::after,
.bs-popover-auto[x-placement^="left"] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #181e21;
}
.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 14px;
  color: #5bc0de;
  background-color: #13171a;
  border-bottom: 1px solid #080a0b;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}
.popover-body {
  padding: 0.5rem 0.75rem;
  color: #dcdedd;
}
.carousel {
  position: relative;
}
.carousel.pointer-event {
  -ms-touch-action: pan-y;
      touch-action: pan-y;
}
.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}
.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transition: -webkit-transform 0.6s ease-in-out;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}
@media screen and (prefers-reduced-motion: reduce) {
  .carousel-item {
    -webkit-transition: none;
    transition: none;
  }
}
.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}
.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}
.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}
.carousel-fade .carousel-item {
  opacity: 0;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transform: none;
          transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  -webkit-transition: 0s 0.6s opacity;
  transition: 0s 0.6s opacity;
}
@media screen and (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    -webkit-transition: none;
    transition: none;
  }
}
.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 15%;
  color: #f2f4f3;
  text-align: center;
  opacity: 0.5;
  -webkit-transition: opacity 0.35s ease;
  transition: opacity 0.35s ease;
}
@media screen and (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    -webkit-transition: none;
    transition: none;
  }
}
.carousel-control-prev:hover,
.carousel-control-next:hover,
.carousel-control-prev:focus,
.carousel-control-next:focus {
  color: #f2f4f3;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}
.carousel-control-prev {
  left: 0;
}
.carousel-control-next {
  right: 0;
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 0.5% 100%;
}
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23f2f4f3' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}
.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23f2f4f3' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}
.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  -webkit-box-flex: 0;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #13171a;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  -webkit-transition: opacity 0.6s ease;
  transition: opacity 0.6s ease;
}
@media screen and (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    -webkit-transition: none;
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}
.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #dcdedd;
  text-align: center;
}
@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
          animation: spinner-border 0.75s linear infinite;
}
.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}
@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}
@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: spinner-grow 0.75s linear infinite;
          animation: spinner-grow 0.75s linear infinite;
}
.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}
.align-baseline {
  vertical-align: baseline !important;
}
.align-top {
  vertical-align: top !important;
}
.align-middle {
  vertical-align: middle !important;
}
.align-bottom {
  vertical-align: bottom !important;
}
.align-text-bottom {
  vertical-align: text-bottom !important;
}
.align-text-top {
  vertical-align: text-top !important;
}
.bg-primary {
  background-color: #181e21 !important;
}
a.bg-primary:hover,
button.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:focus {
  background-color: #030303 !important;
}
.bg-secondary {
  background-color: #273034 !important;
}
a.bg-secondary:hover,
button.bg-secondary:hover,
a.bg-secondary:focus,
button.bg-secondary:focus {
  background-color: #111517 !important;
}
.bg-success {
  background-color: #1a936f !important;
}
a.bg-success:hover,
button.bg-success:hover,
a.bg-success:focus,
button.bg-success:focus {
  background-color: #12684e !important;
}
.bg-info {
  background-color: #4b9eb6 !important;
}
a.bg-info:hover,
button.bg-info:hover,
a.bg-info:focus,
button.bg-info:focus {
  background-color: #3b7f93 !important;
}
.bg-warning {
  background-color: #fff07c !important;
}
a.bg-warning:hover,
button.bg-warning:hover,
a.bg-warning:focus,
button.bg-warning:focus {
  background-color: #ffea49 !important;
}
.bg-danger {
  background-color: #E03E40 !important;
}
a.bg-danger:hover,
button.bg-danger:hover,
a.bg-danger:focus,
button.bg-danger:focus {
  background-color: #ca2123 !important;
}
.bg-light {
  background-color: #dcdedd !important;
}
a.bg-light:hover,
button.bg-light:hover,
a.bg-light:focus,
button.bg-light:focus {
  background-color: #c2c5c4 !important;
}
.bg-dark {
  background-color: #181e21 !important;
}
a.bg-dark:hover,
button.bg-dark:hover,
a.bg-dark:focus,
button.bg-dark:focus {
  background-color: #030303 !important;
}
.bg-white {
  background-color: #f2f4f3 !important;
}
.bg-transparent {
  background-color: transparent !important;
}
.border {
  border: 1px solid #3d464b !important;
}
.border-top {
  border-top: 1px solid #3d464b !important;
}
.border-right {
  border-right: 1px solid #3d464b !important;
}
.border-bottom {
  border-bottom: 1px solid #3d464b !important;
}
.border-left {
  border-left: 1px solid #3d464b !important;
}
.border-0 {
  border: 0 !important;
}
.border-top-0 {
  border-top: 0 !important;
}
.border-right-0 {
  border-right: 0 !important;
}
.border-bottom-0 {
  border-bottom: 0 !important;
}
.border-left-0 {
  border-left: 0 !important;
}
.border-primary {
  border-color: #181e21 !important;
}
.border-secondary {
  border-color: #273034 !important;
}
.border-success {
  border-color: #1a936f !important;
}
.border-info {
  border-color: #4b9eb6 !important;
}
.border-warning {
  border-color: #fff07c !important;
}
.border-danger {
  border-color: #E03E40 !important;
}
.border-light {
  border-color: #dcdedd !important;
}
.border-dark {
  border-color: #181e21 !important;
}
.border-white {
  border-color: #f2f4f3 !important;
}
.rounded-sm {
  border-radius: 0.2rem !important;
}
.rounded {
  border-radius: 0.25rem !important;
}
.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}
.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}
.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}
.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}
.rounded-lg {
  border-radius: 0.3rem !important;
}
.rounded-circle {
  border-radius: 50% !important;
}
.rounded-pill {
  border-radius: 50rem !important;
}
.rounded-0 {
  border-radius: 0 !important;
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}
.d-none {
  display: none !important;
}
.d-inline {
  display: inline !important;
}
.d-inline-block {
  display: inline-block !important;
}
.d-block {
  display: block !important;
}
.d-table {
  display: table !important;
}
.d-table-row {
  display: table-row !important;
}
.d-table-cell {
  display: table-cell !important;
}
.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}
.d-inline-flex {
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}
@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-md-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-print-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}
.embed-responsive-16by9::before {
  padding-top: 56.25%;
}
.embed-responsive-4by3::before {
  padding-top: 75%;
}
.embed-responsive-1by1::before {
  padding-top: 100%;
}
.flex-row {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
      -ms-flex-direction: row !important;
          flex-direction: row !important;
}
.flex-column {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
      -ms-flex-direction: column !important;
          flex-direction: column !important;
}
.flex-row-reverse {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
      -ms-flex-direction: row-reverse !important;
          flex-direction: row-reverse !important;
}
.flex-column-reverse {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: reverse !important;
      -ms-flex-direction: column-reverse !important;
          flex-direction: column-reverse !important;
}
.flex-wrap {
  -ms-flex-wrap: wrap !important;
      flex-wrap: wrap !important;
}
.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important;
}
.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
      flex-wrap: wrap-reverse !important;
}
.flex-fill {
  -webkit-box-flex: 1 !important;
      -ms-flex: 1 1 auto !important;
          flex: 1 1 auto !important;
}
.flex-grow-0 {
  -webkit-box-flex: 0 !important;
      -ms-flex-positive: 0 !important;
          flex-grow: 0 !important;
}
.flex-grow-1 {
  -webkit-box-flex: 1 !important;
      -ms-flex-positive: 1 !important;
          flex-grow: 1 !important;
}
.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
      flex-shrink: 0 !important;
}
.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
      flex-shrink: 1 !important;
}
.justify-content-start {
  -webkit-box-pack: start !important;
      -ms-flex-pack: start !important;
          justify-content: flex-start !important;
}
.justify-content-end {
  -webkit-box-pack: end !important;
      -ms-flex-pack: end !important;
          justify-content: flex-end !important;
}
.justify-content-center {
  -webkit-box-pack: center !important;
      -ms-flex-pack: center !important;
          justify-content: center !important;
}
.justify-content-between {
  -webkit-box-pack: justify !important;
      -ms-flex-pack: justify !important;
          justify-content: space-between !important;
}
.justify-content-around {
  -ms-flex-pack: distribute !important;
      justify-content: space-around !important;
}
.align-items-start {
  -webkit-box-align: start !important;
      -ms-flex-align: start !important;
          align-items: flex-start !important;
}
.align-items-end {
  -webkit-box-align: end !important;
      -ms-flex-align: end !important;
          align-items: flex-end !important;
}
.align-items-center {
  -webkit-box-align: center !important;
      -ms-flex-align: center !important;
          align-items: center !important;
}
.align-items-baseline {
  -webkit-box-align: baseline !important;
      -ms-flex-align: baseline !important;
          align-items: baseline !important;
}
.align-items-stretch {
  -webkit-box-align: stretch !important;
      -ms-flex-align: stretch !important;
          align-items: stretch !important;
}
.align-content-start {
  -ms-flex-line-pack: start !important;
      align-content: flex-start !important;
}
.align-content-end {
  -ms-flex-line-pack: end !important;
      align-content: flex-end !important;
}
.align-content-center {
  -ms-flex-line-pack: center !important;
      align-content: center !important;
}
.align-content-between {
  -ms-flex-line-pack: justify !important;
      align-content: space-between !important;
}
.align-content-around {
  -ms-flex-line-pack: distribute !important;
      align-content: space-around !important;
}
.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
      align-content: stretch !important;
}
.align-self-auto {
  -ms-flex-item-align: auto !important;
      align-self: auto !important;
}
.align-self-start {
  -ms-flex-item-align: start !important;
      align-self: flex-start !important;
}
.align-self-end {
  -ms-flex-item-align: end !important;
      align-self: flex-end !important;
}
.align-self-center {
  -ms-flex-item-align: center !important;
      align-self: center !important;
}
.align-self-baseline {
  -ms-flex-item-align: baseline !important;
      align-self: baseline !important;
}
.align-self-stretch {
  -ms-flex-item-align: stretch !important;
      align-self: stretch !important;
}
@media (min-width: 576px) {
  .flex-sm-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important;
  }
  .flex-sm-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important;
  }
  .justify-content-sm-between {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important;
  }
  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
  }
  .align-items-sm-start {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important;
  }
  .align-items-sm-end {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important;
  }
  .align-items-sm-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important;
  }
  .align-items-sm-baseline {
    -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important;
  }
  .align-items-sm-stretch {
    -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important;
  }
  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
  }
  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
  }
  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important;
  }
  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
  }
  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
  }
  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
  }
  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important;
  }
  .align-self-sm-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important;
  }
  .align-self-sm-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important;
  }
  .align-self-sm-center {
    -ms-flex-item-align: center !important;
        align-self: center !important;
  }
  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
  }
  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important;
  }
  .flex-md-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important;
  }
  .flex-md-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important;
  }
  .justify-content-md-end {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important;
  }
  .justify-content-md-center {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important;
  }
  .justify-content-md-between {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important;
  }
  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
  }
  .align-items-md-start {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important;
  }
  .align-items-md-end {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important;
  }
  .align-items-md-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important;
  }
  .align-items-md-baseline {
    -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important;
  }
  .align-items-md-stretch {
    -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important;
  }
  .align-content-md-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
  }
  .align-content-md-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
  }
  .align-content-md-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important;
  }
  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
  }
  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
  }
  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
  }
  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important;
  }
  .align-self-md-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important;
  }
  .align-self-md-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important;
  }
  .align-self-md-center {
    -ms-flex-item-align: center !important;
        align-self: center !important;
  }
  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
  }
  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important;
  }
  .flex-lg-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important;
  }
  .justify-content-lg-between {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important;
  }
  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
  }
  .align-items-lg-start {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important;
  }
  .align-items-lg-end {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important;
  }
  .align-items-lg-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important;
  }
  .align-items-lg-baseline {
    -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important;
  }
  .align-items-lg-stretch {
    -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important;
  }
  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
  }
  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
  }
  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important;
  }
  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
  }
  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
  }
  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
  }
  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important;
  }
  .align-self-lg-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important;
  }
  .align-self-lg-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important;
  }
  .align-self-lg-center {
    -ms-flex-item-align: center !important;
        align-self: center !important;
  }
  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
  }
  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important;
  }
  .flex-xl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important;
  }
  .justify-content-xl-between {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important;
  }
  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
  }
  .align-items-xl-start {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important;
  }
  .align-items-xl-end {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important;
  }
  .align-items-xl-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important;
  }
  .align-items-xl-baseline {
    -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important;
  }
  .align-items-xl-stretch {
    -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important;
  }
  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
  }
  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
  }
  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important;
  }
  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
  }
  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
  }
  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
  }
  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important;
  }
  .align-self-xl-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important;
  }
  .align-self-xl-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important;
  }
  .align-self-xl-center {
    -ms-flex-item-align: center !important;
        align-self: center !important;
  }
  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
  }
  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important;
  }
}
.float-left {
  float: left  !important;
}
.float-right {
  float: right !important;
}
.float-none {
  float: none  !important;
}
@media (min-width: 576px) {
  .float-sm-left {
    float: left  !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none  !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left  !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none  !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left  !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none  !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left  !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none  !important;
  }
}
.overflow-auto {
  overflow: auto !important;
}
.overflow-hidden {
  overflow: hidden !important;
}
.position-static {
  position: static !important;
}
.position-relative {
  position: relative !important;
}
.position-absolute {
  position: absolute !important;
}
.position-fixed {
  position: fixed !important;
}
.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}
.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}
.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}
@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}
.shadow-sm {
  -webkit-box-shadow: 2px 1px 1px #13171a !important;
          box-shadow: 2px 1px 1px #13171a !important;
}
.shadow {
  -webkit-box-shadow: 3px 2px 1px #13171a !important;
          box-shadow: 3px 2px 1px #13171a !important;
}
.shadow-lg {
  -webkit-box-shadow: 0 1rem 3rem #181e21 !important;
          box-shadow: 0 1rem 3rem #181e21 !important;
}
.shadow-none {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}
.w-25 {
  width: 25% !important;
}
.w-50 {
  width: 50% !important;
}
.w-75 {
  width: 75% !important;
}
.w-100 {
  width: 100% !important;
}
.w-auto {
  width: auto !important;
}
.h-25 {
  height: 25% !important;
}
.h-50 {
  height: 50% !important;
}
.h-75 {
  height: 75% !important;
}
.h-100 {
  height: 100% !important;
}
.h-auto {
  height: auto !important;
}
.mw-100 {
  max-width: 100% !important;
}
.mh-100 {
  max-height: 100% !important;
}
.min-vw-100 {
  min-width: 100vw !important;
}
.min-vh-100 {
  min-height: 100vh !important;
}
.vw-100 {
  width: 100vw !important;
}
.vh-100 {
  height: 100vh !important;
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}
.m-0 {
  margin: 0 !important;
}
.mt-0,
.my-0 {
  margin-top: 0 !important;
}
.mr-0,
.mx-0 {
  margin-right: 0 !important;
}
.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}
.ml-0,
.mx-0 {
  margin-left: 0 !important;
}
.m-1 {
  margin: 0.25rem !important;
}
.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}
.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}
.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}
.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}
.m-2 {
  margin: 0.5rem !important;
}
.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}
.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}
.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}
.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}
.m-3 {
  margin: 1rem !important;
}
.mt-3,
.my-3 {
  margin-top: 1rem !important;
}
.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}
.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}
.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}
.m-4 {
  margin: 1.5rem !important;
}
.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}
.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}
.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}
.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}
.m-5 {
  margin: 3rem !important;
}
.mt-5,
.my-5 {
  margin-top: 3rem !important;
}
.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}
.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}
.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}
.m-n1 {
  margin: -0.25rem !important;
}
.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}
.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}
.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}
.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}
.m-n2 {
  margin: -0.5rem !important;
}
.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}
.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}
.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}
.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}
.m-n3 {
  margin: -1rem !important;
}
.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}
.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}
.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}
.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}
.m-n4 {
  margin: -1.5rem !important;
}
.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}
.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}
.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}
.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}
.m-n5 {
  margin: -3rem !important;
}
.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}
.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}
.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}
.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}
.p-0 {
  padding: 0 !important;
}
.pt-0,
.py-0 {
  padding-top: 0 !important;
}
.pr-0,
.px-0 {
  padding-right: 0 !important;
}
.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}
.pl-0,
.px-0 {
  padding-left: 0 !important;
}
.p-1 {
  padding: 0.25rem !important;
}
.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}
.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}
.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}
.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}
.p-2 {
  padding: 0.5rem !important;
}
.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}
.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}
.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}
.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}
.p-3 {
  padding: 1rem !important;
}
.pt-3,
.py-3 {
  padding-top: 1rem !important;
}
.pr-3,
.px-3 {
  padding-right: 1rem !important;
}
.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}
.pl-3,
.px-3 {
  padding-left: 1rem !important;
}
.p-4 {
  padding: 1.5rem !important;
}
.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}
.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}
.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}
.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}
.p-5 {
  padding: 3rem !important;
}
.pt-5,
.py-5 {
  padding-top: 3rem !important;
}
.pr-5,
.px-5 {
  padding-right: 3rem !important;
}
.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}
.pl-5,
.px-5 {
  padding-left: 3rem !important;
}
.p-n1 {
  padding: -0.25rem !important;
}
.pt-n1,
.py-n1 {
  padding-top: -0.25rem !important;
}
.pr-n1,
.px-n1 {
  padding-right: -0.25rem !important;
}
.pb-n1,
.py-n1 {
  padding-bottom: -0.25rem !important;
}
.pl-n1,
.px-n1 {
  padding-left: -0.25rem !important;
}
.p-n2 {
  padding: -0.5rem !important;
}
.pt-n2,
.py-n2 {
  padding-top: -0.5rem !important;
}
.pr-n2,
.px-n2 {
  padding-right: -0.5rem !important;
}
.pb-n2,
.py-n2 {
  padding-bottom: -0.5rem !important;
}
.pl-n2,
.px-n2 {
  padding-left: -0.5rem !important;
}
.p-n3 {
  padding: -1rem !important;
}
.pt-n3,
.py-n3 {
  padding-top: -1rem !important;
}
.pr-n3,
.px-n3 {
  padding-right: -1rem !important;
}
.pb-n3,
.py-n3 {
  padding-bottom: -1rem !important;
}
.pl-n3,
.px-n3 {
  padding-left: -1rem !important;
}
.p-n4 {
  padding: -1.5rem !important;
}
.pt-n4,
.py-n4 {
  padding-top: -1.5rem !important;
}
.pr-n4,
.px-n4 {
  padding-right: -1.5rem !important;
}
.pb-n4,
.py-n4 {
  padding-bottom: -1.5rem !important;
}
.pl-n4,
.px-n4 {
  padding-left: -1.5rem !important;
}
.p-n5 {
  padding: -3rem !important;
}
.pt-n5,
.py-n5 {
  padding-top: -3rem !important;
}
.pr-n5,
.px-n5 {
  padding-right: -3rem !important;
}
.pb-n5,
.py-n5 {
  padding-bottom: -3rem !important;
}
.pl-n5,
.px-n5 {
  padding-left: -3rem !important;
}
.m-auto {
  margin: auto !important;
}
.mt-auto,
.my-auto {
  margin-top: auto !important;
}
.mr-auto,
.mx-auto {
  margin-right: auto !important;
}
.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}
.ml-auto,
.mx-auto {
  margin-left: auto !important;
}
@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .p-sm-n1 {
    padding: -0.25rem !important;
  }
  .pt-sm-n1,
  .py-sm-n1 {
    padding-top: -0.25rem !important;
  }
  .pr-sm-n1,
  .px-sm-n1 {
    padding-right: -0.25rem !important;
  }
  .pb-sm-n1,
  .py-sm-n1 {
    padding-bottom: -0.25rem !important;
  }
  .pl-sm-n1,
  .px-sm-n1 {
    padding-left: -0.25rem !important;
  }
  .p-sm-n2 {
    padding: -0.5rem !important;
  }
  .pt-sm-n2,
  .py-sm-n2 {
    padding-top: -0.5rem !important;
  }
  .pr-sm-n2,
  .px-sm-n2 {
    padding-right: -0.5rem !important;
  }
  .pb-sm-n2,
  .py-sm-n2 {
    padding-bottom: -0.5rem !important;
  }
  .pl-sm-n2,
  .px-sm-n2 {
    padding-left: -0.5rem !important;
  }
  .p-sm-n3 {
    padding: -1rem !important;
  }
  .pt-sm-n3,
  .py-sm-n3 {
    padding-top: -1rem !important;
  }
  .pr-sm-n3,
  .px-sm-n3 {
    padding-right: -1rem !important;
  }
  .pb-sm-n3,
  .py-sm-n3 {
    padding-bottom: -1rem !important;
  }
  .pl-sm-n3,
  .px-sm-n3 {
    padding-left: -1rem !important;
  }
  .p-sm-n4 {
    padding: -1.5rem !important;
  }
  .pt-sm-n4,
  .py-sm-n4 {
    padding-top: -1.5rem !important;
  }
  .pr-sm-n4,
  .px-sm-n4 {
    padding-right: -1.5rem !important;
  }
  .pb-sm-n4,
  .py-sm-n4 {
    padding-bottom: -1.5rem !important;
  }
  .pl-sm-n4,
  .px-sm-n4 {
    padding-left: -1.5rem !important;
  }
  .p-sm-n5 {
    padding: -3rem !important;
  }
  .pt-sm-n5,
  .py-sm-n5 {
    padding-top: -3rem !important;
  }
  .pr-sm-n5,
  .px-sm-n5 {
    padding-right: -3rem !important;
  }
  .pb-sm-n5,
  .py-sm-n5 {
    padding-bottom: -3rem !important;
  }
  .pl-sm-n5,
  .px-sm-n5 {
    padding-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .p-md-n1 {
    padding: -0.25rem !important;
  }
  .pt-md-n1,
  .py-md-n1 {
    padding-top: -0.25rem !important;
  }
  .pr-md-n1,
  .px-md-n1 {
    padding-right: -0.25rem !important;
  }
  .pb-md-n1,
  .py-md-n1 {
    padding-bottom: -0.25rem !important;
  }
  .pl-md-n1,
  .px-md-n1 {
    padding-left: -0.25rem !important;
  }
  .p-md-n2 {
    padding: -0.5rem !important;
  }
  .pt-md-n2,
  .py-md-n2 {
    padding-top: -0.5rem !important;
  }
  .pr-md-n2,
  .px-md-n2 {
    padding-right: -0.5rem !important;
  }
  .pb-md-n2,
  .py-md-n2 {
    padding-bottom: -0.5rem !important;
  }
  .pl-md-n2,
  .px-md-n2 {
    padding-left: -0.5rem !important;
  }
  .p-md-n3 {
    padding: -1rem !important;
  }
  .pt-md-n3,
  .py-md-n3 {
    padding-top: -1rem !important;
  }
  .pr-md-n3,
  .px-md-n3 {
    padding-right: -1rem !important;
  }
  .pb-md-n3,
  .py-md-n3 {
    padding-bottom: -1rem !important;
  }
  .pl-md-n3,
  .px-md-n3 {
    padding-left: -1rem !important;
  }
  .p-md-n4 {
    padding: -1.5rem !important;
  }
  .pt-md-n4,
  .py-md-n4 {
    padding-top: -1.5rem !important;
  }
  .pr-md-n4,
  .px-md-n4 {
    padding-right: -1.5rem !important;
  }
  .pb-md-n4,
  .py-md-n4 {
    padding-bottom: -1.5rem !important;
  }
  .pl-md-n4,
  .px-md-n4 {
    padding-left: -1.5rem !important;
  }
  .p-md-n5 {
    padding: -3rem !important;
  }
  .pt-md-n5,
  .py-md-n5 {
    padding-top: -3rem !important;
  }
  .pr-md-n5,
  .px-md-n5 {
    padding-right: -3rem !important;
  }
  .pb-md-n5,
  .py-md-n5 {
    padding-bottom: -3rem !important;
  }
  .pl-md-n5,
  .px-md-n5 {
    padding-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .p-lg-n1 {
    padding: -0.25rem !important;
  }
  .pt-lg-n1,
  .py-lg-n1 {
    padding-top: -0.25rem !important;
  }
  .pr-lg-n1,
  .px-lg-n1 {
    padding-right: -0.25rem !important;
  }
  .pb-lg-n1,
  .py-lg-n1 {
    padding-bottom: -0.25rem !important;
  }
  .pl-lg-n1,
  .px-lg-n1 {
    padding-left: -0.25rem !important;
  }
  .p-lg-n2 {
    padding: -0.5rem !important;
  }
  .pt-lg-n2,
  .py-lg-n2 {
    padding-top: -0.5rem !important;
  }
  .pr-lg-n2,
  .px-lg-n2 {
    padding-right: -0.5rem !important;
  }
  .pb-lg-n2,
  .py-lg-n2 {
    padding-bottom: -0.5rem !important;
  }
  .pl-lg-n2,
  .px-lg-n2 {
    padding-left: -0.5rem !important;
  }
  .p-lg-n3 {
    padding: -1rem !important;
  }
  .pt-lg-n3,
  .py-lg-n3 {
    padding-top: -1rem !important;
  }
  .pr-lg-n3,
  .px-lg-n3 {
    padding-right: -1rem !important;
  }
  .pb-lg-n3,
  .py-lg-n3 {
    padding-bottom: -1rem !important;
  }
  .pl-lg-n3,
  .px-lg-n3 {
    padding-left: -1rem !important;
  }
  .p-lg-n4 {
    padding: -1.5rem !important;
  }
  .pt-lg-n4,
  .py-lg-n4 {
    padding-top: -1.5rem !important;
  }
  .pr-lg-n4,
  .px-lg-n4 {
    padding-right: -1.5rem !important;
  }
  .pb-lg-n4,
  .py-lg-n4 {
    padding-bottom: -1.5rem !important;
  }
  .pl-lg-n4,
  .px-lg-n4 {
    padding-left: -1.5rem !important;
  }
  .p-lg-n5 {
    padding: -3rem !important;
  }
  .pt-lg-n5,
  .py-lg-n5 {
    padding-top: -3rem !important;
  }
  .pr-lg-n5,
  .px-lg-n5 {
    padding-right: -3rem !important;
  }
  .pb-lg-n5,
  .py-lg-n5 {
    padding-bottom: -3rem !important;
  }
  .pl-lg-n5,
  .px-lg-n5 {
    padding-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .p-xl-n1 {
    padding: -0.25rem !important;
  }
  .pt-xl-n1,
  .py-xl-n1 {
    padding-top: -0.25rem !important;
  }
  .pr-xl-n1,
  .px-xl-n1 {
    padding-right: -0.25rem !important;
  }
  .pb-xl-n1,
  .py-xl-n1 {
    padding-bottom: -0.25rem !important;
  }
  .pl-xl-n1,
  .px-xl-n1 {
    padding-left: -0.25rem !important;
  }
  .p-xl-n2 {
    padding: -0.5rem !important;
  }
  .pt-xl-n2,
  .py-xl-n2 {
    padding-top: -0.5rem !important;
  }
  .pr-xl-n2,
  .px-xl-n2 {
    padding-right: -0.5rem !important;
  }
  .pb-xl-n2,
  .py-xl-n2 {
    padding-bottom: -0.5rem !important;
  }
  .pl-xl-n2,
  .px-xl-n2 {
    padding-left: -0.5rem !important;
  }
  .p-xl-n3 {
    padding: -1rem !important;
  }
  .pt-xl-n3,
  .py-xl-n3 {
    padding-top: -1rem !important;
  }
  .pr-xl-n3,
  .px-xl-n3 {
    padding-right: -1rem !important;
  }
  .pb-xl-n3,
  .py-xl-n3 {
    padding-bottom: -1rem !important;
  }
  .pl-xl-n3,
  .px-xl-n3 {
    padding-left: -1rem !important;
  }
  .p-xl-n4 {
    padding: -1.5rem !important;
  }
  .pt-xl-n4,
  .py-xl-n4 {
    padding-top: -1.5rem !important;
  }
  .pr-xl-n4,
  .px-xl-n4 {
    padding-right: -1.5rem !important;
  }
  .pb-xl-n4,
  .py-xl-n4 {
    padding-bottom: -1.5rem !important;
  }
  .pl-xl-n4,
  .px-xl-n4 {
    padding-left: -1.5rem !important;
  }
  .p-xl-n5 {
    padding: -3rem !important;
  }
  .pt-xl-n5,
  .py-xl-n5 {
    padding-top: -3rem !important;
  }
  .pr-xl-n5,
  .px-xl-n5 {
    padding-right: -3rem !important;
  }
  .pb-xl-n5,
  .py-xl-n5 {
    padding-bottom: -3rem !important;
  }
  .pl-xl-n5,
  .px-xl-n5 {
    padding-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
.text-monospace {
  font-family: "Roboto Mono", Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}
.text-justify {
  text-align: justify !important;
}
.text-wrap {
  white-space: normal !important;
}
.text-nowrap {
  white-space: nowrap !important;
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.text-capitalize {
  text-transform: capitalize !important;
}
.font-weight-light {
  font-weight: 300 !important;
}
.font-weight-lighter {
  font-weight: lighter !important;
}
.font-weight-normal {
  font-weight: 400 !important;
}
.font-weight-bold {
  font-weight: 700 !important;
}
.font-weight-bolder {
  font-weight: bolder !important;
}
.font-italic {
  font-style: italic !important;
}
.text-white {
  color: #f2f4f3 !important;
}
.text-primary {
  color: #181e21 !important;
}
a.text-primary:hover,
a.text-primary:focus {
  color: #000000 !important;
}
.text-secondary {
  color: #273034 !important;
}
a.text-secondary:hover,
a.text-secondary:focus {
  color: #060808 !important;
}
.text-success {
  color: #1a936f !important;
}
a.text-success:hover,
a.text-success:focus {
  color: #0f523e !important;
}
.text-info {
  color: #4b9eb6 !important;
}
a.text-info:hover,
a.text-info:focus {
  color: #346f80 !important;
}
.text-warning {
  color: #fff07c !important;
}
a.text-warning:hover,
a.text-warning:focus {
  color: #ffe730 !important;
}
.text-danger {
  color: #E03E40 !important;
}
a.text-danger:hover,
a.text-danger:focus {
  color: #b51d1f !important;
}
.text-light {
  color: #dcdedd !important;
}
a.text-light:hover,
a.text-light:focus {
  color: #b5b9b7 !important;
}
.text-dark {
  color: #181e21 !important;
}
a.text-dark:hover,
a.text-dark:focus {
  color: #000000 !important;
}
.text-body {
  color: #dcdedd !important;
}
.text-muted {
  color: #c7c8c7 !important;
}
.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}
.text-white-50 {
  color: rgba(242, 244, 243, 0.5) !important;
}
.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
  /* & when not (@ignore-warning) {
		// @warn "The `text-hide()` mixin has been deprecated as of v4.1.0. It will be removed entirely in v5.";
	} */
}
.text-decoration-none {
  text-decoration: none !important;
}
.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}
.text-reset {
  color: inherit !important;
}
.visible {
  visibility: visible !important;
}
.invisible {
  visibility: hidden !important;
}
@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #273034;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #f2f4f3 !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #3d464b !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #3d464b;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #3d464b;
  }
}
/*/THEMES/SLATE: Cytube*/
/*@/themes/_styles/cytube/_core.less*/
footer {
  background-color: #161a20 !important;
}
/*@/themes/_styles/cytube/_card.less*/
.login.card {
  -webkit-box-shadow: 4px 5px 5px #13171a;
          box-shadow: 4px 5px 5px #13171a;
}
.login.card .card-header {
  border-bottom-color: #2a3439;
}
.register.card {
  -webkit-box-shadow: 4px 5px 5px #13171a;
          box-shadow: 4px 5px 5px #13171a;
}
.register.card .card-header {
  border-bottom-color: #2a3439;
}
.account-channels.card {
  -webkit-box-shadow: 4px 5px 5px #13171a;
          box-shadow: 4px 5px 5px #13171a;
}
.account-channels.card .card-header {
  border-bottom-color: #2a3439;
}
.profile.card {
  -webkit-box-shadow: 4px 5px 5px #13171a;
          box-shadow: 4px 5px 5px #13171a;
}
.profile.card .card-header {
  border-bottom-color: #2a3439;
}
.account-edit.card {
  -webkit-box-shadow: 4px 5px 5px #13171a;
          box-shadow: 4px 5px 5px #13171a;
}
.account-edit.card .card-header {
  border-bottom-color: #2a3439;
}
.password-reset.card {
  -webkit-box-shadow: 4px 5px 5px #13171a;
          box-shadow: 4px 5px 5px #13171a;
}
.password-reset.card .card-header {
  border-bottom-color: #2a3439;
}
.acp-log-viewer.card {
  -webkit-box-shadow: 4px 5px 5px #13171a;
          box-shadow: 4px 5px 5px #13171a;
}
.acp-log-viewer.card .card-header {
  border-bottom-color: #2a3439;
}
/*@/themes/_styles/cytube/_chat.less*/
#chatheader {
  background-color: #1b2125;
  border-style: solid;
  border-width: 1px;
  border-color: #1b2125;
  border-bottom-color: #3d464b;
  border-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.usercount {
  font-size: 16px;
}
#chatDragHandle {
  background-color: #273034;
}
.drink {
  border-color: #ff9900;
}
#drinkcount {
  background-color: #000000;
  color: #ffffff;
}
.emotelist-table td {
  color: #c8c8c8;
  background-color: #161a20;
}
.messagesTextarea {
  background-color: #15181a;
  color: #c3c3c3;
}
.nick-highlight {
  background-color: #555555;
}
.nick-hover {
  background-color: #111122;
}
#newmessages-indicator-bghack {
  background: #000000;
  background: rgba(0, 0, 0, 0.9);
}
#messagebuffer {
  background-color: #1d2428;
  border: 1px solid #3d464b;
  border-top-color: transparent;
}
.server-msg-disconnect,
.server-msg-reconnect {
  line-height: 2;
}
.server-msg-disconnect {
  border: 1px solid #ff0000;
  color: #ff0000;
  background-color: rgba(129, 20, 21, 0.1);
}
.server-msg-reconnect {
  border: 1px solid #009900;
  color: #009900;
  background-color: rgba(18, 100, 18, 0.1);
}
.action {
  color: #888888;
  font-style: italic;
}
.greentext {
  color: #789922;
}
.server-whisper {
  color: #888888;
  font-size: 8pt;
  font-style: italic;
}
.shout {
  color: #ff0000;
  font-size: 18pt;
  font-weight: bold;
}
.spoiler {
  background-color: #000000;
  color: #000000;
}
.spoiler:hover {
  color: #ffffff;
}
.chat-timestamp,
.timestamp {
  font-size: 8pt;
}
.chat-shadow {
  color: #777777;
  text-decoration: line-through;
}
@media (prefers-reduced-motion: reduce) {
  * {
    -webkit-animation: none !important;
            animation: none !important;
    -webkit-transition: none !important;
    transition: none !important;
  }
}
.highlight {
  background: rgba(38, 47, 53, 0.7);
  border: 1px solid #FF9900;
  border-radius: 5px;
  display: inline-block;
  width: 100%;
  padding: 2px;
}
.highlight::after {
  background: url("/svg/ui/ui-coolpoints.svg") 5px 0 no-repeat;
  background-size: auto;
  background-size: contain;
  content: "";
  float: right;
  width: 20px;
  height: 15px;
}
/* custom_css.css unsure if all the diff animation overloads are needed but w/e */
.shrink {
  font-size: 0px;
  -webkit-animation: shrink 15s;
          animation: shrink 15s;
}
@-webkit-keyframes shrink {
  from {
    font-size: inherit;
  }
  to {
    font-size: 0px;
  }
}
@keyframes shrink {
  from {
    font-size: inherit;
  }
  to {
    font-size: 0px;
  }
}
/* Begin stupid amount of css for no reason */
.total-criticality {
  display: block;
  font-size: 9px;
  -webkit-animation: text-shadow 1s ease-in-out infinite, vibrate 2s ease-in 1, vibrate 1s ease-in 2s 1, vibrate 750ms ease-in 3s 1, vibrate 500ms ease-in 3.75s 1, vibrate 250ms ease-in 4.25s infinite, flicker-out 5s linear 6s both;
          animation: text-shadow 1s ease-in-out infinite, vibrate 2s ease-in 1, vibrate 1s ease-in 2s 1, vibrate 750ms ease-in 3s 1, vibrate 500ms ease-in 3.75s 1, vibrate 250ms ease-in 4.25s infinite, flicker-out 5s linear 6s both;
}
/* https://codepen.io/erinesullivan/pen/ZMreqJ */
@-webkit-keyframes text-shadow {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    text-shadow: 0 0 0 #0c2ffb, 0 0 0 #2cfcfd, 0 0 0 #fb203b, 0 0 0 #fefc4b;
  }
  30% {
    -webkit-transform: translateX(-0.2em);
            transform: translateX(-0.2em);
    text-shadow: 0.45em 0 0 #0c2ffb, 0.35em 0 0 #2cfcfd, -0.325em 0 0 #fb203b, -0.225em 0 0 #fefc4b;
  }
  40% {
    -webkit-transform: translateY(0em);
            transform: translateY(0em);
    text-shadow: -0.45em 0 0 #0c2ffb, -0.35em 0 0 #2cfcfd, 0.325em 0 0 #fb203b, 0.225em 0 0 0 #fefc4b;
  }
  50% {
    -webkit-transform: translateX(0.2em);
            transform: translateX(0.2em);
    text-shadow: 0.03125em 0 0 #0c2ffb, 0.0625em 0 0 #2cfcfd, -0.03125em 0 0 #fb203b, -0.0625em 0 0 #fefc4b;
  }
  80% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    text-shadow: 0 0 0 #0c2ffb, 0 0 0 #2cfcfd, 0 0 0 #fb203b, 0 0 0 #fefc4b;
  }
}
@keyframes text-shadow {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    text-shadow: 0 0 0 #0c2ffb, 0 0 0 #2cfcfd, 0 0 0 #fb203b, 0 0 0 #fefc4b;
  }
  30% {
    -webkit-transform: translateX(-0.2em);
            transform: translateX(-0.2em);
    text-shadow: 0.45em 0 0 #0c2ffb, 0.35em 0 0 #2cfcfd, -0.325em 0 0 #fb203b, -0.225em 0 0 #fefc4b;
  }
  40% {
    -webkit-transform: translateY(0em);
            transform: translateY(0em);
    text-shadow: -0.45em 0 0 #0c2ffb, -0.35em 0 0 #2cfcfd, 0.325em 0 0 #fb203b, 0.225em 0 0 0 #fefc4b;
  }
  50% {
    -webkit-transform: translateX(0.2em);
            transform: translateX(0.2em);
    text-shadow: 0.03125em 0 0 #0c2ffb, 0.0625em 0 0 #2cfcfd, -0.03125em 0 0 #fb203b, -0.0625em 0 0 #fefc4b;
  }
  80% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    text-shadow: 0 0 0 #0c2ffb, 0 0 0 #2cfcfd, 0 0 0 #fb203b, 0 0 0 #fefc4b;
  }
}
/* https://animista.net/ */
@-webkit-keyframes vibrate {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  10% {
    -webkit-transform: translate(-2px, -2px);
    transform: translate(-2px, -2px);
  }
  20% {
    -webkit-transform: translate(2px, -2px);
    transform: translate(2px, -2px);
  }
  30% {
    -webkit-transform: translate(-2px, 2px);
    transform: translate(-2px, 2px);
  }
  40% {
    -webkit-transform: translate(2px, 2px);
    transform: translate(2px, 2px);
  }
  50% {
    -webkit-transform: translate(-2px, -2px);
    transform: translate(-2px, -2px);
  }
  60% {
    -webkit-transform: translate(2px, -2px);
    transform: translate(2px, -2px);
  }
  70% {
    -webkit-transform: translate(-2px, 2px);
    transform: translate(-2px, 2px);
  }
  80% {
    -webkit-transform: translate(-2px, -2px);
    transform: translate(-2px, -2px);
  }
  90% {
    -webkit-transform: translate(2px, -2px);
    transform: translate(2px, -2px);
  }
  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}
@keyframes vibrate {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  10% {
    -webkit-transform: translate(-2px, -2px);
    transform: translate(-2px, -2px);
  }
  20% {
    -webkit-transform: translate(2px, -2px);
    transform: translate(2px, -2px);
  }
  30% {
    -webkit-transform: translate(-2px, 2px);
    transform: translate(-2px, 2px);
  }
  40% {
    -webkit-transform: translate(2px, 2px);
    transform: translate(2px, 2px);
  }
  50% {
    -webkit-transform: translate(-2px, -2px);
    transform: translate(-2px, -2px);
  }
  60% {
    -webkit-transform: translate(2px, -2px);
    transform: translate(2px, -2px);
  }
  70% {
    -webkit-transform: translate(-2px, 2px);
    transform: translate(-2px, 2px);
  }
  80% {
    -webkit-transform: translate(-2px, -2px);
    transform: translate(-2px, -2px);
  }
  90% {
    -webkit-transform: translate(2px, -2px);
    transform: translate(2px, -2px);
  }
  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}
/* https://animista.net/ */
@-webkit-keyframes flicker-out {
  0% {
    opacity: 1;
  }
  13.9% {
    opacity: 1;
  }
  14% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  14.9% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  15% {
    opacity: 1;
  }
  22.9% {
    opacity: 1;
  }
  23% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  24.9% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  25% {
    opacity: 1;
  }
  34.9% {
    opacity: 1;
  }
  35% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  39.9% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  40% {
    opacity: 1;
  }
  42.9% {
    opacity: 1;
  }
  43% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  44.9% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  45% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  54.9% {
    opacity: 1;
  }
  55% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  69.4% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  69.5% {
    opacity: 1;
  }
  69.9% {
    opacity: 1;
  }
  70% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  79.4% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  79.9% {
    opacity: 1;
  }
  80% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  89.8% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  89.9% {
    opacity: 1;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  90% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  100% {
    opacity: 0;
  }
}
@keyframes flicker-out {
  0% {
    opacity: 1;
  }
  13.9% {
    opacity: 1;
  }
  14% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  14.9% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  15% {
    opacity: 1;
  }
  22.9% {
    opacity: 1;
  }
  23% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  24.9% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  25% {
    opacity: 1;
  }
  34.9% {
    opacity: 1;
  }
  35% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  39.9% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  40% {
    opacity: 1;
  }
  42.9% {
    opacity: 1;
  }
  43% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  44.9% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  45% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  54.9% {
    opacity: 1;
  }
  55% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  69.4% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  69.5% {
    opacity: 1;
  }
  69.9% {
    opacity: 1;
  }
  70% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  79.4% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  79.9% {
    opacity: 1;
  }
  80% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  89.8% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  89.9% {
    opacity: 1;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  90% {
    opacity: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  100% {
    opacity: 0;
  }
}
.danmu {
  width: 100vw;
  right: 0;
  position: absolute;
  z-index: 10;
  white-space: nowrap;
  -webkit-transform: translate(100%);
          transform: translate(100%);
}
.secretary {
  position: fixed;
  pointer-events: none;
  z-index: 11;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  font-size: 72px;
}
.focus-in-blur-out-expand {
  -webkit-animation: text-focus-in 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both, blur-out-expand 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) 5s both;
  animation: text-focus-in 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both, blur-out-expand 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) 5s both;
}
@-webkit-keyframes blur-out-expand {
  0% {
    -webkit-filter: blur(0.01);
    filter: blur(0.01);
  }
  100% {
    letter-spacing: 1em;
    -webkit-filter: blur(12px) opacity(0%);
    filter: blur(12px) opacity(0%);
  }
}
@keyframes blur-out-expand {
  0% {
    -webkit-filter: blur(0.01);
    filter: blur(0.01);
  }
  100% {
    letter-spacing: 1em;
    -webkit-filter: blur(12px) opacity(0%);
    filter: blur(12px) opacity(0%);
  }
}
@-webkit-keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}
@keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}
.secretary .text-lottery[data-text*="european"],
.danmu .text-lottery[data-text*="european"] {
  -webkit-animation: rainbow 0.5s;
  animation: rainbow 0.5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}
.secretary span:not(.text-lottery),
.danmu span:not(.text-lottery) {
  text-shadow: 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000;
  letter-spacing: 0.2px;
}
#messagebuffer [data-text*="european"] {
  font-size: 14px;
  -webkit-animation: rainbow 5;
  animation: rainbow 5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}
#messagebuffer .text-lottery[data-text*="european"] {
  font-size: 14px;
  -webkit-animation: rainbow 0.5s;
  animation: rainbow 0.5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}
/*@/themes/_styles/cytube/_login.less*/
input#logout.navbar-link:hover {
  color: #e2484a;
}
/*@/themes/_styles/cytube/_msg.less*/
/*@/themes/_styles/cytube/_nav.less*/
nav.navbar {
  background-color: #1b2125;
}
nav.navbar .navbar-nav .nav-item.divider {
  color: #262F35;
}
#logout {
  color: #c7c8c7;
}
#logout:hover,
#logout:focus {
  color: #e2484a;
}
/*@/themes/_styles/cytube/_playlist.less*/
#plmeta {
  border: 1px solid #aaaaaa;
}
/*@/themes/_styles/cytube/_poll.less*/
.poll-notify {
  color: #ff9900;
  font-size: 14pt;
  font-weight: bold;
}
/*@/themes/_styles/cytube/_profile.less*/
.profile-box {
  background-color: #13171a;
  border: 1px solid #c7c8c7;
  border-radius: 3px;
  -webkit-box-shadow: 2px 3px 1px #13171a;
          box-shadow: 2px 3px 1px #13171a;
  color: #dcdedd;
  padding: 5px;
  z-index: 9999;
}
.profile-image {
  border: 1px solid #c7c8c7;
  border-radius: 3px;
}
/*@/themes/_styles/cytube/_queue.less*/
.queue_active {
  background-color: #181e21;
  background-image: none /* linear-gradient(#8a9196, #7a8288, #70787d) */;
  background-repeat: no-repeat;
}
.queue_active.queue_temp {
  background-color: #1d2428;
  background-image: none /* url(/img/stripe-diagonal-black.png) */;
  background-repeat: no-repeat /* repeat */;
}
.queue_entry {
  background-color: #1d2428;
  border-color: #181e21;
}
/*@/themes/_styles/cytube/_svg.less*/
.svgTrash {
  fill: #dcd4d4;
}
/*@/themes/_styles/cytube/_tablesorter.less*/
table.tablesorter {
  border: 1px solid invisible;
  -webkit-box-shadow: 2px 3px 1px #13171a;
          box-shadow: 2px 3px 1px #13171a;
}
table.tablesorter thead tr th,
table.tablesorter tfoot tr th {
  padding: 4px;
}
table.tablesorter thead tr th {
  background-color: #3c424a;
  border: 1px solid black;
}
table.tablesorter thead tr .headerSortUp,
table.tablesorter thead tr .headerSortDown {
  background-color: inherit;
}
table.tablesorter tfoot tr th {
  background-color: #3c424a;
  border: 1px solid black;
}
.tablesorter-filter-row td {
  background: inherit;
}
.tablesorter-filter-row .disabled {
  opacity: 0.5;
  filter: alpha(opacity=50);
  cursor: not-allowed;
}
.tablesorter-filter-row.hideme td {
  padding: 2px;
}
.tablesorter-filter {
  background-color: #3c424a;
  border-width: 0 0 1px 1px;
  border-color: black;
  color: #bbb;
}
/*@/themes/_styles/cytube/_user.less*/
#userlist {
  background-color: #181e21;
  border: 1px solid #3d464b;
}
.userlist_owner {
  color: #ff9900 !important;
}
.user-dropdown {
  color: #c8c8c8;
  background-color: #13171a;
}
/*@/themes/_styles/cytube/_video.less*/
#videowrap-header {
  background-color: #1b2125;
  border-style: solid;
  border-width: 1px;
  border-color: #1b2125;
  border-bottom-color: #3d464b;
  border-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
#currenttitle {
  color: #dcdedd;
  font-size: 16px;
}
#currenttitle #currenttitle-content {
  color: #5bc0de;
  font-size: 16px;
  font-weight: bold;
}
.embed-responsive {
  background-color: #181e21;
}
/*@/themes/_styles/cytube/_utilities.less*/
/*@/themes/slate/cytube/utilities/helpers.less*/
.hyperlink {
  color: #5bc0de;
  text-decoration: underline;
  cursor: pointer;
}
.linkLoading {
  color: lightblue;
}
.linkBroken {
  color: lightcoral;
}
.markedForDelete {
  background-color: darkred;
}
.validationError {
  border-color: red;
  border-width: 2px;
  border-style: solid;
}
/*!/THEMES/SLATE: Coolhole*/
/*@/themes/_styles/coolhole/_core.less*/
.floatingDivBody {
  max-height: 560px;
  min-height: 150px;
}
.floatingDivTable {
  max-height: 560px;
}
.floatingDivHeader {
  background-color: #6d6c6c;
  border-radius: 6px 6px 0 0;
  min-height: 16.43px;
  padding: 4px 15px 0 15px;
}
.floatingDivClose {
  background: 0 0;
  border: 0;
  color: #000;
  font-size: 21px;
  font-weight: 700;
  line-height: 1;
  opacity: 0.5;
  padding: 4px 0 0 0;
  text-shadow: 0 1px 0 #fff;
}
.propTypeDiv,
.propDetailsDiv {
  padding: 10px;
}
#qualitywrap {
  margin-right: 5px;
}
ul.bugList > li {
  margin: 15px 0 0 0;
}
.debugConsole {
  height: 400px;
}
table td {
  max-width: 200px;
}
table.storeItemTable thead tr th,
table.screenList thead tr th,
table.overviewTable thead tr th,
table.storeItemTable tfoot tr th,
table.screenList tfoot tr th,
table.overviewTable tfoot tr th {
  border: 1px solid black;
  background-color: #3c424a;
  padding: 4px;
  text-align: center;
}
table.storeItemTable thead tr .headerSortUp,
table.screenList thead tr .headerSortUp,
table.overviewTable thead tr .headerSortUp,
table.storeItemTable thead tr .headerSortDown,
table.screenList thead tr .headerSortDown,
table.overviewTable thead tr .headerSortDown {
  /*background-color: #8dbdd8;*/
}
table.storeItemTable tbody tr:nth-child(even),
table.screenList tbody tr:nth-child(even),
table.overviewTable tbody tr:nth-child(even) {
  background-color: #272d35;
}
table.storeItemTable tbody tr:nth-child(odd),
table.screenList tbody tr:nth-child(odd),
table.overviewTable tbody tr:nth-child(odd) {
  /*background-color:#F0F0F6;*/
}
table.screenList tbody tr:hover {
  background-color: #6c7156;
}
.countTable thead tr th {
  border-color: gray;
  border-style: solid;
  border-width: 0 0 0.5px 0;
}
.tblSelectDark {
  color: white;
  background-color: #272b30;
}
#user-icon-container {
  display: inline;
}
#navDropdownAccount:after,
#navDropdownGameOptions:after,
#navDropdownBroteLinks:after {
  display: none;
}
#broteLinksDD .dropdown-toggle {
  display: none;
}
#BroteDDImgPatreon,
#BroteDDImgTwitter,
#BroteDDImgVideos,
#BroteDDImgDonate,
#BroteDDImgGold,
#BroteDDImgDiscord {
  margin-left: 8px;
}
#user-nav-item .dropdown-menu #navDropdownAccount {
  padding-top: 0px;
  padding-bottom: 0px;
}
#user-nav-item .dropdown-menu #navDropdownAccount:after {
  display: none;
}
#user-nav-item .dropdown-menu #navDropdownAccount span {
  padding-top: 0px;
  padding-left: 4px;
  padding-right: 12px;
}
@media (min-width: 768px) {
  #user-nav-item .dropdown-menu.show {
    left: -154px;
  }
  #broteLinksDD {
    left: -100px;
  }
}
/*@/themes/_styles/coolhole/_action.less*/
.actionSelected {
  background-color: red;
}
.actionItemName {
  color: white;
  border-radius: 5px;
  padding: 4px 8px;
}
.actionBtn {
  background-color: #454c53;
  border: 1px solid black;
  border-radius: 5px;
  color: white;
  padding: 4px 8px;
}
.actionBtn:hover,
.actionBtn:focus {
  background-color: #696d71;
}
.actionBtn:disabled {
  background-color: rgba(69, 76, 83, 0.5);
  color: gray;
}
.actionBtnSelected {
  background-color: red;
  border: 1px solid black;
  border-radius: 5px;
  color: white;
  padding: 4px 8px;
}
.actionBtnSelected:hover,
.actionBtnSelected:focus {
  background-color: darkred;
}
.actionBtnCooldownTime {
  color: black;
}
/*@/themes/_styles/coolhole/_character.less*/
.charEquipContainer {
  padding: 12px 3%;
}
.charEquipMajor {
  border: 4px solid #f2f269;
}
.charEquipMinor {
  border: 3px solid #c1c1c1;
  margin: 1px;
}
.charEquipOffhand {
  border: 3px solid #1c63fc;
}
.hpBarColor {
  background-color: red;
}
.hpBarHUD {
  border: 2px solid silver;
}
.hpBarUserList {
  border-color: silver;
  border-width: 0px 1px 1px 1px;
  height: 4px;
  margin-top: -3px;
}
.charItem {
  background-color: #0e0e0e;
  height: 64px;
  width: 64px;
}
.charItemHud {
  border: 1px solid #7d7d7d;
  margin: 3px;
}
.charItemInventory {
  border: 1px solid #7d7d7d;
}
.charItemHighlight:hover {
  -webkit-box-shadow: 0 0 43px -12px yellow inset;
          box-shadow: 0 0 43px -12px yellow inset;
}
.charItemCooldown {
  background-color: rgba(0, 0, 0, 0.5);
}
.charInnerPanel {
  background-color: #727279;
  border: 3px solid #aeaeae;
  padding: 6px 8px 12px 8px;
}
.charInnerPanelBlack {
  background-color: #0e0e0e;
  border: 3px solid #aeaeae;
  padding: 6px 8px 12px 8px;
}
.charPanel {
  border: 7px solid gray;
  padding: 6px;
}
.charPanelContainer {
  padding: 8px;
}
.charPanelTitle {
  font-weight: bold;
  text-align: left;
}
.charStatDesc {
  background-color: #0e0e0e;
  border: 3px solid #717171;
  height: 180px;
}
.itemDetailActionContainer {
  border: 1px solid white;
  padding: 4px;
}
.itemDetailIllegal {
  color: red;
}
.itemDetailSilenced {
  color: #908e8e;
}
.itemDetailDescription {
  color: #d8bb90;
}
.itemDetailTitle {
  text-decoration: underline;
  font-weight: bold;
}
.charStatusBox {
  border: 1px solid white;
  width: 20px;
}
.charStatusBoxCriminal {
  background-color: blue;
}
.charStatusBoxArrested {
  background-color: black;
}
.charStatusBoxVampire {
  background-color: red;
}
/*@/themes/_styles/coolhole/_combat.less*/
#combatInfo {
  background-color: #1b2125;
  border: 1px solid #3d464b;
  border-bottom-color: transparent;
  border-top-color: transparent;
  height: 60px;
  min-height: 20px;
}
.combatMsgDamage {
  color: #e06b18;
}
.combatMsgGainHp {
  color: #35c11e;
}
.combatMsgLoseHp {
  color: #f7022d;
}
.combatMsgGainPts {
  color: #edf330;
}
.combatMsgLosePts {
  color: #ef7474;
}
/*@/themes/_styles/coolhole/_game.less*/
.goodBuff {
  color: green;
}
.badBuff {
  color: red;
}
.dmgPerSecBuff {
  color: orange;
}
.gameBtn {
  background-color: #454c53;
  border: 1px solid black;
  border-radius: 5px;
  color: white;
  padding: 8px;
}
.gameBtn:hover {
  background-color: #696d71;
}
.gameBtn:active {
  background-color: #1c1f23;
}
.gameBtnFlat {
  background-color: #454c53;
  border: 1px solid black;
  border-radius: 5px;
  color: white;
  font-size: 14px;
  padding: 4px 8px 4px 8px;
}
.gameBtnFlat:hover {
  background-color: #696d71;
}
.gameBtnFlat:active {
  background-color: #1c1f23;
}
.gameNewButton {
  background-color: #194a19;
  color: white;
}
.gameNewButton:hover {
  background-color: #113111;
}
.gameDeleteButton {
  background-color: #6d0300;
  color: white;
}
.gameDeleteButton:hover {
  background-color: #370200;
}
.gameFormLabel {
  font-size: 20px;
}
.gameFormSelect {
  color: black;
}
.gameFormScrollPanel {
  border: 1px solid #1c1e22;
}
.gameHoverSection {
  width: 200px;
  float: left;
  padding: 5px;
}
.gameHoverLabel {
  font-style: italic;
}
.gameHoverLabelBold {
  font-weight: bold;
}
.gameHoverValue {
  font-style: italic;
  float: right;
}
.gameHoverTitle {
  text-decoration: underline;
  font-weight: bold;
}
.hoverBox {
  border: 1px solid #aaaaaa;
  border-radius: 5px;
  padding: 5px;
  color: #c8c8c8;
  background-color: #161a20;
  font-size: 12px;
}
.gameMessageOkay {
  color: white;
}
.gameMessageImportant {
  color: #f9f95c;
}
.gameMessageError {
  color: #f55f5f;
}
.gameMessageCriticalError {
  color: red;
}
.gameModal {
  width: 900px;
  max-width: 50%;
  top: 455px;
  left: 475px;
}
.gameModalContent {
  background-color: #2e3338;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
          box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
}
.gameModalHeader {
  min-height: 16.43px;
  padding: 15px 15px 0 15px;
}
.gameModalTitle {
  font-size: 24px;
}
.gameModalBody {
  min-height: 560px;
}
.gameModalList {
  border: 1px solid #1c1e22;
}
.gameModalListWrap {
  padding: 10px 20px 15px 20px;
}
.gameModalListLabel {
  font-size: 18px;
  background-color: #3e444c;
  padding-left: 3px;
}
.gameModalListTable {
  height: 250px;
}
.gameModalListTableBig {
  height: 500px;
}
.gameModalDetails {
  border: 1px solid #1c1e22;
}
.gameModalDetailsWrap {
  padding: 0 20px 20px 20px;
}
.gameModalDetailsLabel {
  font-size: 18px;
  background-color: #3e444c;
  padding-left: 3px;
}
.gameModalSave {
  padding: 20px;
  text-align: right;
  border-top: 1px solid #1c1e22;
}
.gameProfileImage {
  max-width: 80px;
  max-height: 80px;
  border: 1px solid #aaaaaa;
  border-radius: 5px;
  float: right;
}
/*@/themes/_styles/coolhole/_header.less*/
.no-channel.header-row {
  background-color: #1b2125;
}
.no-channel.header-row .header-icon {
  margin-top: 2px;
  width: 38px;
  height: 38px;
}
/*@/themes/_styles/coolhole/_hud.less*/
.hud {
  background-color: rgba(59, 64, 69, 0);
  height: 104px;
}
.hudTopPart {
  background-color: rgba(59, 64, 69, 0);
}
.hudPanel {
  background-color: #727279;
  padding: 6px 8px 6px 8px;
  border: 3px solid #aeaeae;
}
.hudPlaceholder {
  background-color: #272b30;
  height: 150px;
}
.hudMessage {
  background-color: rgba(0, 0, 0, 0.7);
  padding: 10px;
}
.hudMessageContainer {
  font-size: 24px;
}
.hudStats {
  width: 220px;
}
.hudAction {
  width: 320px;
}
.hudInnerAction {
  width: 226px;
  float: left;
  padding-left: 4px;
}
.hudEquip {
  width: 548px;
}
.hudQuickSlot {
  width: 600px;
}
.hudAbilities {
  width: 240px;
}
.hudScroll {
  width: 42px;
}
.hudRespawn {
  background-color: yellow;
}
.hudScrollButton {
  background-color: #454c53;
  border: 1px solid black;
  border-radius: 5px;
  color: white;
  padding: 0;
}
.hudScrollButton:hover {
  background-color: #696d71;
}
.hudScrollButton:active {
  background-color: #1c1f23;
}
.hudActionDropdown {
  background-color: #161a20;
  border: 1px solid #aaaaaa;
  border-radius: 5px;
  color: #c8c8c8;
  max-width: 200px;
  padding: 5px;
  z-index: 1070;
}
.hudActionDropdownRespawn {
  background-color: #161a20;
  border: 1px solid #aaaaaa;
  border-radius: 5px;
  bottom: 110px;
  color: #c8c8c8;
  left: 58px;
  max-width: 200px;
  padding: 5px;
}
.hudBuff {
  background-color: #0e0e0e;
  border: 1px solid silver;
  height: 32px;
  margin: 0 4px;
  width: 32px;
}
.hudBuffContainer {
  bottom: 105px;
  height: 34px;
  left: 24px;
}
.actionPanelCooldown {
  color: #e8c816;
}
.actionPanelReady {
  color: #22f122;
}
/*@/themes/_styles/coolhole/_item.less*/
.itemName {
  font-size: 12px;
  text-align: center;
}
.itemLoading {
  font-size: 10px;
  font-style: italic;
  top: 36px;
}
.itemUses {
  top: 44px;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}
.itemActionAddBtn {
  background-color: lightblue;
}
.itemActionAddDiv {
  color: blue;
}
.itemActionDetails {
  padding-bottom: 90px;
}
.addItemPropertyBtn {
  background-color: lightblue;
}
table.itemActionTable {
  border: 2px solid white;
}
table.itemActionTable tr th,
table.itemActionTable tr td {
  border: 1px solid white;
  text-align: center;
}
table.itemActionTable tr th {
  font-weight: bold;
  border-bottom: 2px solid white;
}
table.itemActionTable tr td {
  padding: 7px 0;
}
/*@/themes/_styles/coolhole/_job.less*/
.jobPickerPanel {
  padding: 5px;
}
.jobPickerTextArea {
  min-height: 300px;
  padding: 15px;
}
/*@/themes/_styles/coolhole/_user.less*/
.user-dropdown-line {
  margin-bottom: 2px;
  margin-top: 2px;
  height: 1px;
  background-color: #cccccc;
}
#userlist {
  width: 150px;
  border-right-color: transparent;
  border-top-color: transparent;
  font-size: 9pt;
  padding: 0;
  height: 229px;
  margin-bottom: 0;
  min-height: 100px;
}
.userlist_siteadmin {
  color: #cc0000 !important;
  font-weight: bold !important;
}
.userlist_owner {
  color: #ff9900 !important;
  font-weight: bold !important;
}
.userlist_op {
  color: #00aa00 !important;
  font-weight: normal !important;
}
.userlist_guest {
  color: #888888 !important;
  font-weight: normal !important;
}
.nick-highlight {
  background-color: #ddffdd;
}
.nick-hover {
  background-color: #13171a;
}
#userInfoHoverCriminal {
  color: lightblue;
}
#userInfoHoverArrested {
  color: white;
}
#userInfoHoverVampire {
  color: red;
}
.jailbars {
  background-image: url(../../img/jailbars.png);
  background-repeat: repeat;
}
.criminalState {
  background-color: blue;
}
.vampireState {
  border: 1px solid red;
}
.victimRevealState {
  border: 1px solid yellow;
}
.victimRevealChance {
  color: yellow;
}
.onlineStatus {
  color: green;
}
.offlineStatus {
  color: gray;
}
/*@/themes/_styles/coolhole/_icons.less*/
.ch-icon {
  width: 24px;
  height: 24px;
  fill: #dcdedd;
}
.ch-icon.warning {
  fill: #E03E40;
}
.ch-icon.success {
  fill: #1a936f;
}
.ch-icon.info {
  fill: #4b9eb6;
}
.ch-icon.input-group-addon-icon {
  width: 22px;
  height: 22px;
}
.ch-icon .job-icon .job-icon-bg .job-icon-citizen {
  fill: #3B7B8E;
  stroke: #F2F4F3;
}
.ch-icon .job-icon .job-icon-bg .job-icon-scitizen {
  fill: #E03E40;
  stroke: #F2F4F3;
}
.ch-icon .job-icon .job-icon-bg .job-icon-skeleton {
  fill: #13171A;
  stroke: #F2F4F3;
}
.ch-icon .job-icon .job-icon-person {
  fill: #F2F4F3;
}
.ch-icon .job-icon .job-icon-symbol .job-icon-skeleton {
  fill: #F2F4F3;
}
